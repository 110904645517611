import { Component, Inject, OnInit, EventEmitter, Output, ViewEncapsulation, ChangeDetectorRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormBuilder } from '@angular/forms';
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { SaveService } from '../../../../services/save.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatChipInputEvent } from "@angular/material/chips";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

@Component({
    selector: "contractitem-filter",
    templateUrl: './contractitem-filter.component.html',
    styleUrls: ['../../../../style/form.component.less',
        'contractitem-filter.component.less',
        '../../../../style/expand-collapse-panel.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ],
    encapsulation: ViewEncapsulation.None
})

export class ContractItemFilterComponent implements OnInit {

    form!: FormGroup;
    @Output() outputFilter = new EventEmitter<ContractFilter>();
    @Output() switchActive = new EventEmitter<boolean>();

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];

    contractNumbers: string[] = [];
    unitIds: string[] = [];
    customerIds: string[] = [];
    contractLines: string[] = [];
    contractLinesLocale: string[] = [];
    contractTypes: string[] = [];
    contractTypesLocale: string[] = [];
    anyFilterApplied: boolean = false;

    constructor(
        private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , private saveService: SaveService
        , private cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.saveService.addListener(this);
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            CustomerId: [''],
            ContractNumber: [''],
            UnitId: [''],
            ContractLine: [''],
            ContractLineLocale: [''],
            ContractType: [''],
            ContractTypeLocale: [''],
            showOnlyActive: [false] 
        });
    }

    changeTypeButton() {
        if (this.form)
            return this.contractNumbers.length < 1 && this.customerIds.length < 1
                && this.unitIds.length < 1 && this.contractLines.length < 1 && this.contractLinesLocale.length < 1
                && this.contractTypes.length < 1 && this.contractTypesLocale.length < 1;
        else
            return false;
    }

    onFilter() {
        let filter: ContractFilter
        if (this.form) {
            filter = {
                'CustomerIds': this.customerIds,
                'ContractNumbers': this.contractNumbers,
                'UnitIds': this.unitIds,
                'ContractLines': this.contractLines,
                'ContractLinesLocale': this.contractLinesLocale,
                'ContractTypes': this.contractTypes,
                'ContractTypesLocale': this.contractTypesLocale,
                'OnlyActiveContracts': !this.changeTypeButton() ? true : false
            }
        }
        else return;

        this.outputFilter.emit(filter);
    }

    toggleActive(event: MatSlideToggleChange) {
        this.switchActive.emit(event.checked);
    }

    applyFilter(event: MatChipInputEvent, list: string): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            switch (list) {
                case 'contractnumbers':
                    this.contractNumbers.push(value.replace('+', '').trim()); break;
                case 'unitids':
                    this.unitIds.push(value.replace('+', '').trim()); break;
                case 'customerids':
                    this.customerIds.push(value.replace('+', '').trim());
                    break;
                case 'contractlines':
                    this.contractLines.push(value.replace('+', '').trim());
                    break;
                case 'contractLinesLocale':
                    this.contractLinesLocale.push(value.replace('+', '').trim());
                    break;
                case 'contractTypes':
                    this.contractTypes.push(value.replace('+', '').trim());
                    break;
                case 'contractTypesLocale':
                    this.contractTypesLocale.push(value.replace('+', '').trim());
                    break;                                      
            }
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(item: string, list: string): void {
        let index = -1
        switch (list) {
            case 'contractnumbers':
                index = this.contractNumbers.indexOf(item);
                if (index >= 0)
                    this.contractNumbers.splice(index, 1);
                break;
            case 'unitids':
                index = this.unitIds.indexOf(item);
                if (index >= 0)
                    this.unitIds.splice(index, 1);
                break;
            case 'customerids':
                index = this.customerIds.indexOf(item);
                if (index >= 0)
                    this.customerIds.splice(index, 1);
                break;
            case 'contractlines':
                index = this.contractLines.indexOf(item);
                if (index >= 0)
                    this.contractLines.splice(index, 1);
                break;
            case 'contractLinesLocale':
                index = this.contractLinesLocale.indexOf(item);
                if (index >= 0)
                    this.contractLinesLocale.splice(index, 1);
                break;
            case 'contractTypes':
                index = this.contractTypes.indexOf(item);
                if (index >= 0)
                    this.contractTypes.splice(index, 1);
                break;   
            case 'contractTypesLocale':
                index = this.contractTypesLocale.indexOf(item);
                if (index >= 0)
                    this.contractTypesLocale.splice(index, 1);
                break;                  
        }
    }

    onFilterHandled() {
        if (!this.changeTypeButton()) {
            this.anyFilterApplied = true;
            this.form.patchValue({ showOnlyActive: true });
            this.cdr.detectChanges();
        }
        else
            this.anyFilterApplied = false;
    }

    onClearFilter() {
        this.contractNumbers = [];
        this.unitIds = [];
        this.customerIds = [];
        this.contractLines = [];
        this.contractLinesLocale = [];
        this.contractTypes = [];
        this.contractTypesLocale = [];
        this.anyFilterApplied = false;
        this.form.patchValue({ showOnlyActive: false });
        this.cdr.detectChanges();
    }
}