<div class="container-tab-lists new-table-style" id="niops">

    <div class="inner-tcontainer-tab">

        <h2>{{title}}</h2>
        <div style="display:flow-root">
            <div style="text-align:right; float:right">
                <a class='kpi-button-menu' title="Excel" (click)="onClickExcelButton()" *ngIf="!isExcelModeEnabled">
                    Excel
                </a>
                <a class='kpi-button-menu' title="Download" *ngIf="isExcelModeEnabled" (click)="downloadExcelInformation()">
                    <span class='glyphicon glyphicon-download'></span> Download
                </a>

                <a class='kpi-button-menu' title="Upload" *ngIf="isExcelModeEnabled" (click)="goUploadFileView()">
                    <span class='glyphicon glyphicon-upload'></span> Upload
                </a>

                <a class='kpi-button-menu' title="Cancel" *ngIf="isExcelModeEnabled" (click)="onClickCancelButton()">
                    {{'Exit Excel mode'}}
                </a>
                <a class="kpi-button-menu" title="UploadUserHistory" (click)="onClickUploadUserHistory()">
                    Upload User History
                </a>
            </div>
        </div>
    </div>
</div>