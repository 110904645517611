import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, filter } from 'rxjs';
import { DialogService } from '../../services/dialog.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { SaveService } from '../../services/save.service';
import { Utils } from '../../utils/utils';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'fiscalyear',
    template: `
        Fiscal Year:<br />
        <ng-select 
            (change)="onSelect($event)" 
            style="width:120px; border: 1px white solid" 
            class="top-main-select"
            [items]="list"
            bindLabel="label"
            bindValue="value"
            [closeOnSelect]="true"
            [searchable]="true"
            [(ngModel)]="defaults.fiscalYear">
        </ng-select>
    `
})
export class FiscalYearComponent implements OnInit, OnDestroy {
    list: any[] = [];
    value: number = 0;

    mersyLinks = [
        "/actualreporting",
        "/actualbranchreporting",
        "/oplkpimanagement",
        "/oplkpibranchreporting",
        "/file/15", // Actual Reporting
        "/file/16", // Yearly OPL
        "/file/17", // Monthly OPL
        "/file/18", // Actual Branch Reporting
        "/file/19", // Yearly OPL Branch Reporting
        "/file/20"  // Monthly OPL Branch Reporting
    ];

    marketLinks = [
        "/segment-sheet",
        "/market-sheet",
        "/file/21",
        "/file/22"
    ];

    niOpsLinks = [
        "/niops",
        "/file/27"
    ];

    previousMersyRel: boolean | null = null;
    previousMQspec: boolean | null = null;
    previousNIOps: boolean | null = null;
    protected _onDestroy = new Subject<void>();

    private now: Date;
    private year: number;
    private month: number;

    // Remove this block after September 2025
    isMarketQuerySpec = false;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        public defaults: DefaultValuesService,
        private dialog: DialogService,
        public saveService: SaveService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.now = new Date();
        this.year = this.now.getFullYear();
        this.month = this.now.getMonth();
    }

    async ngOnInit() {
        // Remove this block after September 2025
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(async (event: any) => {
            await this.onInitAbstr(event.url);
        });
    }

    async onInitAbstr(url = "") {
        const isMersyRel = this.mersyLinks.includes(url);
        const isNiOpsRel = this.niOpsLinks.includes(url);
        const marketQueryFrame = this.year === 2024 || (this.month < 9 && this.year === 2025);
        this.isMarketQuerySpec = marketQueryFrame && this.marketLinks.includes(url);

        const changeYearsTrigger = this.shouldChangeYears(isMersyRel, isNiOpsRel);

        if (changeYearsTrigger) {
            this.dialog.tthis = this;

            await this.waitForInitialDataLoad();

            this.list = [];
            const localArrayList: any[] = this.generateYearList(isMersyRel, marketQueryFrame, isNiOpsRel);

            this.updateFiscalYear(isMersyRel, marketQueryFrame, isNiOpsRel);

            this.previousMersyRel = isMersyRel;
            this.previousMQspec = this.isMarketQuerySpec;
            this.previousNIOps = isNiOpsRel;

            this.list = localArrayList;
        }
    }

    private shouldChangeYears(isMersyRel: boolean, isNiOpsRel: boolean): boolean {
        return this.previousNIOps !== isNiOpsRel
            || ((isMersyRel !== this.previousMersyRel && (this.month === 9 || this.month === 10))
                || (this.isMarketQuerySpec !== this.previousMQspec));
    }

    private async waitForInitialDataLoad() {
        while (!this.defaults.loadInitialData) {
            await new Promise(r => setTimeout(r, 1000));
        }
    }

    private generateYearList(isMersyRel: boolean, marketQueryFrame: boolean, isNiOpsRel: boolean): any[] {
        const localArrayList: any[] = [];
        if (isNiOpsRel) {
            if (this.month < 9) {
                localArrayList.push({ 'label': `${this.year - 1}/${this.year}`, 'value': this.year });
                localArrayList.push({ 'label': `${this.year}/${this.year + 1}`, 'value': this.year + 1 });
            } else {
                localArrayList.unshift({ 'label': `${this.year}/${this.year + 1}`, 'value': this.year + 1 });
                localArrayList.unshift({ 'label': `${this.year + 1}/${this.year + 2}`, 'value': this.year + 2 });
            }
        }
        else if (this.defaults.isMersyAdminUser || this.defaults.isMersyOwnerUser) {
            for (let i = this.year + 3; i > this.year - 6; i--) {
                localArrayList.push({ 'label': `${i - 1}/${i}`, 'value': i });
            }
        } else {
            if (this.shouldAddMarketQueryYears(isMersyRel, marketQueryFrame)) {
                const years = marketQueryFrame ? 2024 : this.year;
                localArrayList.push({ 'label': `${years}/${years + 1}`, 'value': years + 1 });
                localArrayList.push({ 'label': `${years - 1}/${years}`, 'value': years });
            } else if (this.month < 9) {
                localArrayList.push({ 'label': `${this.year - 1}/${this.year}`, 'value': this.year });
            } else {
                localArrayList.unshift({ 'label': `${this.year}/${this.year + 1}`, 'value': this.year + 1 });
            }
        }

        return localArrayList;
    }

    private shouldAddMarketQueryYears(isMersyRel: boolean, marketQueryFrame: boolean): boolean {
        return (isMersyRel && (this.month === 9 || this.month === 10) && this.year === 2024)
            || this.isMarketQuerySpec;
    }

    private updateFiscalYear(isMersyRel: boolean, marketQueryFrame: boolean, isNiOpsRel: boolean) {
        if (isNiOpsRel) {
            this.value = this.month < 9 ? this.year : this.year + 1;
            this.changeFiscalYear();
        }
        else if (this.isMarketQuerySpec) {
            this.value = marketQueryFrame ? 2024 : this.year;
            this.changeFiscalYear();
        } else if (this.month === 9 || this.month === 10) {
            this.value = isMersyRel ? this.year + 1 : this.year;
            this.changeFiscalYear();
        } else if (marketQueryFrame) {
            this.value = this.month < 9 ? this.year : this.year + 1;
            this.changeFiscalYear();
        }
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    changeFiscalYear() {
        this.defaults.fiscalYear = this.value;
        this.sendPost();
    }

    onNo() {
        this.saveService.fireCancelAll();
        this.changeFiscalYear();
    }

    onSelect(event: any) {
        if (!event.value) return;
        this.value = +event.value;
        if (this.saveService.isSaveButtonShown()) {
            this.dialog.showConfirmDialog("Do you want to save the changes? If you select No, all changes will be lost!", "Save", this);
        } else {
            this.onNo();
        }
    }

    onYes() {
        this.saveService.fireSave();
        this.changeFiscalYear();
    }

    sendPost() {
        this.defaults.fiscalYear = this.value;
        this.http.post<string>(this.baseUrl + Utils.getFiscalYearAPI(), this.defaults.fiscalYear)
            .subscribe(() => {
                this.defaults.notifyFiscalYearChange();
            }, error => Utils.writeLog(this.http, this.baseUrl, error));
    }
}