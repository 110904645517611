import { Injectable, Inject, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UMPCategory, Utils } from '../utils/utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ComponentTool } from '../interfaces/componenttool';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NavItem } from '../components/sidenav/navitem';

@Injectable()
export class DefaultValuesService implements OnInit {
    onTenantChanged: EventEmitter<string> = new EventEmitter<string>();
    onControllerTypeChanged: EventEmitter<ControllerType> = new EventEmitter<ControllerType>();
    onLanguageChanged: EventEmitter<Language> = new EventEmitter<Language>();
    onStageChanged: EventEmitter<string> = new EventEmitter<string>();
    onManagementIdChanged: EventEmitter<string> = new EventEmitter<string>();

    country: string = '';
    tenant: string = '';
    dialerCode: string = '';
    regulatoryCode: string | undefined = undefined;
    tenants: Tenant[] = [];
    branches: BranchHierarchy[] = [];
    environments: string[] = [];
    waveId: number | undefined = undefined;
    public groupList: { Personae: string; Id: string; UmpCategory: UMPCategory }[] = [];
    public secondaryTenList= new Set<string>();


    public controllerTypeMessageSource = new Subject<ControllerType>();
    controllerTypeMessageChanges$ = this.controllerTypeMessageSource.asObservable();

    public languageMessageSource = new Subject<Language>();
    languageMessageChanges$ = this.languageMessageSource.asObservable();

    public tenantMessageSource = new Subject<string>();
    tenantMessageChanges$ = this.tenantMessageSource.asObservable();

    public stageMessageSource = new Subject<string>();
    stageMessageChanges$ = this.stageMessageSource.asObservable();

    public groupFunctionMessageSource = new Subject<string>();
    groupFunctionMessageChanges$ = this.groupFunctionMessageSource.asObservable();

    public managementIdMessageSource = new Subject<string>();
    managementIdMessageChanges$ = this.managementIdMessageSource.asObservable();

    public fiscalYearMessageSource = new Subject<string>();
    fiscalYearMessageChanges$ = this.fiscalYearMessageSource.asObservable();

    public managementName = "";

    public periodMessageSource = new Subject<string>();
    periodMessageChanges$ = this.periodMessageSource.asObservable();
                 
    public countryMessageSource = new Subject<string>();
    countryMessageChanges$ = this.countryMessageSource.asObservable();

    public isControllerOwnerChange = new Subject<boolean>();
    isControllerOwnerChange$ = this.isControllerOwnerChange.asObservable();

    public resetBulkGroupSource = new Subject<boolean>();
    resetBulkGroupTrigger$ = this.resetBulkGroupSource.asObservable();


    language: Language = {} as Language;
    languages: Language[] = [];

    controllertype: ControllerType = {
        Description: '',
        BK: '',
        ControllerTypeID: -1,
        DeployErrorCodes: false,
        DeployOpModes: false,
        IsUsingErrorCodes: false,
        CountryOwnerName: '',
        CountryOwnerCode: ''
    };

    controllertypes: ControllerType[] = [];
    controllertypesdigitalservices: ControllerType[] = [];

    groupFunction: string = ''
    fiscalYear: number;
    period: number = -1
    managementid = '-1';
    managementids: ManagementId[] = []
    groupFunctions: string[] = []

    isadminuser = false;
    isDomainExpertUser = false;
    isCountryBackendStableUser = false;
    isCountryBackendProdUser = false;
    isControllerOwner = false;
    isHomeUser = false;
    isExternalUser = false;
    isReadonlyUser = false;
    isDevUserManagementPortalUser = false;
    isProdUserManagementPortalUser = false;
    IsMersyUser: boolean | undefined = undefined;
    isMersyAdminUser = false;
    isMersyAdminBranchExcellenceUser = false;     
    isMersyReporterBranchExcellenceUser = false;     
    isMersyViewerBranchExcellenceUser = false;     
    isMersyOwnerUser = false;
    isMersyReporterUser = false;
    isMersyDelegatedUser = false;
    isMersyViewerUser = false;
    isDevMersyUMPUser = false;
    isProdMersyUMPUser = false;
    isEMTManagerUser = false;
    isEMTFactorySupportEngineer = false;
    isMfgManagerUser = false;
    isUMPDataAnalyticsUser = false;
    isHomManagerUser = false;
    isGlobalSystemAdmin = false;
    isUserMgmtFldBranchesUser = false;
    isDevADOGroupUMPUser = false;
    isProdADOGroupUMPUser = false;
    isPBIWorkspaceCreatorUser = false;
    IsLicenseManagerUser = false;
    isMersyReporterBranchReportingUser = false;
    isMersyDelegatedBranchReportingUser = false;
    isMersyViewerBranchReportingUser = false;
    isMersyRevokerUser = false;
    isMersyRevokerBranchReportingUser = false;
    isMersyAdminMarketQueryUser = false;
    isMersyOwnerMarketQueryUser = false;
    isMersyReporterMarketQueryUser = false;
    isMersyDelegateMarketQueryUser = false;
    isMersyViewerMarketQueryUser = false;
    isMersyRevokerMarketQueryUser = false;

    isActualReportingEnabled = false;
    isMonthlyOPLEnabled = false;
    isYearlyOPLEnabled = false; 
    isActualBranchReportingEnabled = false;
    isMonthlyOPLBranchReportingEnabled = false;
    isYearlyOPLBranchReportingEnabled = false;
    isMFGAdminUser = false;
    isMFGEquipmentManagerUser = false;
    isMFGAdminPDCSUser = false;
    isMFGAdminCenterUser = false;
    isMFGContractEngineerUser = false;
    isDESConfAdmUser = false;

    isMersyNiOpsAdminUser = false;
    isMersyNiOpsReporterUser = false;

    public loadInitialData = false
    selectedItem = Number(localStorage.getItem('currentSelectedItem'));

    classname: string = Utils.getDialogHideClassName();

    stage = 1;
    stageAfterRedirect = 1;
    session: Session | undefined;
    componentTool: ComponentTool = ComponentTool.Undefined;

    menuClusters: NavItem[] | undefined;
    components: NavItem[] = [];

    passedTenants: string[] = [];
    passedRoles: string[] = [];
    passedCdpGroups: string[] = [];
    // UMP

    umpFilters: UserFilter = {
        cdpList: [],
        nodeId: [],
        nodes: [],
        page: 0,
        pageSize: 12,
        personaId: [],
        personas: [],
        tenant: [],
        tenants: [],
        userPrincipalName: '',

    };

    //wiki
    wikiTabName: string | null = null;
    routeSeg = "";
    private triggerWikiMng = new Subject<void>();  
    private routeChanged = new Subject<void>();
    public healthScorePopulation = new BehaviorSubject<number | undefined>(undefined);
    healthScorePopObs$ = this.healthScorePopulation.asObservable();

    public currentTenant = new BehaviorSubject<string | undefined>(undefined);
    currentTenantObs$ = this.currentTenant.asObservable();

    public RegCode = new BehaviorSubject<string | undefined>(undefined);
    RegCodeChanged$ = this.RegCode.asObservable();

    public wave = new BehaviorSubject<number | undefined>(undefined);
    wave$ = this.wave.asObservable();


    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private router: Router
        , @Inject('BASE_URL') private baseUrl: string
    ) {
        this.fiscalYear = -1;

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                var temp = event.url.split("/");
                this.routerChange(event.url.length > 1 ? temp[1] : "home")
            }
        });

        this.ngOnInit();
    }

    ngOnInit() {
        this.controllertype.ControllerTypeID = -1;
    }

    /**
     * return true if is to show reference language
     */
    public isToShowReference(): boolean {
        return this.language.LanguageId !== Utils.getDefaultLaguangeID();
    }


    // This is for BehaviorSubject
    //notifyLanguageChange(l: Language) {
    //    this.selectedLanguageSubject.next(l);
    //}


    removeGroupListItem(id: string) {
        const index = this.groupList.findIndex((i) => i.Id === id);
        if (index !== -1) {
            this.groupList.splice(index, 1);
        }
    }
    clearGroupListItem() {
        this.groupList = [];
        this.resetBulkGroupSource.next(true);
    }

    addGroupListItem(item: { Personae: string; Id: string; UmpCategory: UMPCategory }) {
        this.groupList.push(item);
    }

    notifyControllerTypeChange(message: ControllerType) {
        this.controllerTypeMessageSource.next(message);
    }

    notifyLanguageChange(message: Language) {
        this.languageMessageSource.next(message);
        this.currentTenant.next(this.controllertype.BK)
    }
    notifyCurrentTen(ten: string) {
        this.currentTenant.next(ten)
    }

    notifyTenantChange(message: string) {
        this.tenantMessageSource.next(message);
    }

    notifyStageChange(message: string) {
        this.stageMessageSource.next('');
    }

    notifyManagementIdChange() {
        this.managementIdMessageSource.next('');
    }

    notifyFiscalYearChange() {
        this.fiscalYearMessageSource.next('');
    }

    notifyGroupFunctionChange() {
        this.groupFunctionMessageSource.next('');
    }

    notifyPeriodChange() {
        this.periodMessageSource.next('');
    }

    notifyCountryChange(message: string) {
        this.country = message
        this.countryMessageSource.next(message);
    }

    notifyIsControllerOwnerChange() {
        this.isControllerOwnerChange.next(this.isControllerOwner);
    }

    getIsControllerOwner() {
        return this.isControllerOwner;
    }

    canShowUploadButtons() {
        return (this.getIsControllerOwner() || this.language.LanguageCultureName !== 'en-US');
    }  

    //wiki
    routerChange(routeSegment) {
        this.wikiTabName = null;
        this.routeSeg = routeSegment;
        this.routeChangedFunction();
    }

    defaultComponentTab(tthis: any) {
        this.wikiTabName = tthis.tabGroup._tabs?.first?.textLabel?.replaceAll(" ", "_");
    }  

    updateComponentTab(newTabName: any) {
        this.wikiTabName = newTabName?.replaceAll(" ", "_");
        this.routeChangedFunction();
    }

    triggerWikiFunction() {
        this.triggerWikiMng.next();
    }

    get triggerWikiFunctionObservable() {
        return this.triggerWikiMng.asObservable();
    }

    routeChangedFunction() {
        this.routeChanged.next();
    }

    get routeChangedObservable() {
        return this.routeChanged.asObservable();
    }   

    updatePopulation(newValue: number) {
        this.healthScorePopulation.next(newValue);
    }

    

    updateRegCode(newValue) {
        this.RegCode.next(newValue);
    }

    updateWave(newValue) {
        this.waveId = newValue;
        this.wave.next(newValue);
    }

    isDataAnalytStage(): boolean {
        return this.stageAfterRedirect == 4
    }

  
}