import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_ROUTES, Utils } from "../utils/utils";



@Injectable()
export class KpiAdminManagementService {

    constructor(
        private httpClient: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
    ) { }

    getKpiAccountHistory(kpiDefinitionId: number, callback: any | undefined = undefined, errorCallback: any | undefined = undefined) {
        const url = `${this.baseUrl}${API_ROUTES.API_KPIMANAGEMENT}/kpiaccount/${kpiDefinitionId}/history`;
        this.httpClient.get(url).subscribe({
            next: response => {
                if (callback)
                    callback(response);
            }, error: error => {
                Utils.writeLog(this.httpClient, this.baseUrl, error);
                if (errorCallback)
                    errorCallback(error);
            }
        }
        );
    }
}