<!--<div class="container-tab-lists">

    <div class="inner-tcontainer-tab">-->

<iframe title="DevOpsGlobalMonitoring"
        style="height: 90vh; display: block; width: -webkit-fill-available"
        src="https://app.powerbi.com/reportEmbed?reportId=d0e03ba2-14cd-49bd-b7b7-d610a1e94bc0&autoAuth=true&ctid=84d9a216-e285-4aac-b163-0dfd0c074546"
        frameborder="0"
        allowFullScreen="true">
</iframe>
<!--</div>

</div>-->
