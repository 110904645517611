<div class="changelog-dialog">
    <mat-toolbar class="header heading-background">
        <div class="col-lg-9">
            <h5>
                KPI Account Changelog History ({{targetKPI}})
            </h5>
        </div>
        <div class="col-lg-3 search-container">
            <div (change)="updateFilteredHistory()" (keyup)="updateFilteredHistory()" style="width:100%">
                <input class="form-control" type="text" placeholder="Search" [(ngModel)]="searchString">
            </div>
        </div>

    </mat-toolbar>
    <div #tableBody class="custom_small_panelbody_fix" style="visibility:hidden">
        <table #historyTable mat-table
               matSort
               [dataSource]="dataSource"
               multiTemplateDataRows
               class="mat-elevation-z8 mat-table-custom">

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:25%">UTC Date</th>
                <td mat-cell *matCellDef="let row" style="width:25%">
                    {{datePipe.transform(row.utcDate,'yyyy-MM-dd HH:mm')}}
                </td>
            </ng-container>
            <ng-container matColumnDef="upn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:25%">Edited by</th>
                <td mat-cell *matCellDef="let row" style="width:25%">
                    {{row.upn}}
                </td>
            </ng-container>
            <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef style="width:50%">Details</th>
                <td mat-cell *matCellDef="let row; let i = index" style="width:50%">
                    <textareaoverlay [inputControls]="{
                         isOpenOverlay: isOpenTextAreaOverlay,
                         textAreaId: 'Details_ta_' + i,
                         isEditable: false,
                         comment: row.details,
                         maxCommentLength: 4000,
                         maxSubstringSize: 100,
                         minWidth: 500
                     }">
                    </textareaoverlay>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay;">
            </tr>
        </table>
    </div>
    <br />
    <div style="text-align:center">
        <button mat-raised-button class="btn button-color-fix_red " (click)="close()">
            <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
            Back
        </button>
    </div>
</div>
