<div class="container-tab-lists">
    <h2>Add Level</h2>
    <div class="tab-body">
        <div class="tab-content">
            <div class="custom-mat-dialog_v2">
                <form [formGroup]="formGroup" class="internalMax" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-sm-4 form-group">
                            <br />
                            <mat-label> <span>Environment</span><span class="orange-asterisk"> *</span></mat-label>
                        </div>
                        <div class="col-sm-8 form-group">
                            <ng-select [items]="environments" [searchable]="true" [clearable]="false"
                                class="tenantStyling" bindLabel="text" bindValue="value" formControlName="environment"
                                (change)="onEnvironmentChanged($event)" required>
                            </ng-select>
                            <div *ngIf="formGroup.get('environment')?.touched && formGroup.get('environment')?.invalid"
                                class="error">
                                <span *ngIf="formGroup.get('environment')?.hasError('required')">
                                    Environment is required
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 form-group">
                            <br />
                            <mat-label> <span>Tenant</span></mat-label>
                        </div>
                        <div class="col-sm-8 form-group">
                            <ng-select [items]="tenants" [searchable]="true" [clearable]="false" class="tenantStyling"
                                bindLabel="text" bindValue="value" formControlName="tenant">
                            </ng-select>
                        </div>

                    </div>
                    <div class="row">
                        <div class="panel-body">
                            <button (click)="closeDialog($event)" mat-raised-button class="btn  button-color-fix_red ">
                                <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                                Back
                            </button>
                            <button mat-raised-button type="submit" class="btn button-color-fix pull-right ">
                                Save
                                <mat-icon class="icon-color-fix">save</mat-icon>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>