<div class="HS DialerTable long-header">
    <form [formGroup]="form" autocomplete="off" class="box weightageTable">
        <ng-container formArrayName="rows">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mat-table-custom">
                <ng-container matColumnDef="version">
                    <th mat-header-cell *matHeaderCellDef> Software Version </th>
                    <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                        <p>{{getFormValue(i).version}}</p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> Type </th>
                    <td mat-cell *matCellDef="let element; let i = index"
                        [formGroup]="element">
                        <p>{{formatType(element.value.type)}}</p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="familyCode">
                    <th mat-header-cell *matHeaderCellDef> Family code </th>
                    <td mat-cell *matCellDef="let element; let i = index"
                        [formGroup]="element">
                        <p>{{getFormValue(i).familyCode}}</p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="environment">
                    <th mat-header-cell *matHeaderCellDef style="width:16%"> Environment Value </th>
                    <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                        <p *ngIf="!getFormValue(i).onEdit || editableColumn !== ENVIRONMENT">
                            {{formatEnvironmentValue(element.value.environmentValue)}}</p>
                        <ng-select *ngIf="getFormValue(i).onEdit && editableColumn === ENVIRONMENT"
                            [items]="environmentValues"
                            bindLabel="text"
                            bindValue="value"
                            [clearable]="false"
                            [clearOnBackspace]="false"
                            formControlName="environmentValue"
                            [readonly]="!getFormValue(i).onEdit || editableColumn !== ENVIRONMENT">
                        </ng-select>
                    </td>
                </ng-container>
                <ng-container matColumnDef="tenant">
                    <th mat-header-cell *matHeaderCellDef style="width:16%"> Tenant Value </th>
                    <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                        <p *ngIf="!getFormValue(i).onEdit || editableColumn !== TENANT">
                            {{formatTenantValue(element.value.tenantValue, element.value.environmentValue)}}</p>
                        <ng-select *ngIf="getFormValue(i).onEdit && editableColumn === TENANT"
                            [items]="tenantValues"
                            bindLabel="text"
                            bindValue="value"
                            [clearable]="false"
                            [clearOnBackspace]="false"
                            formControlName="tenantValue"
                            [readonly]="!getFormValue(i).onEdit || editableColumn !== TENANT">
                        </ng-select>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                        <button mat-icon-button color="primary" *ngIf="!getFormValue(i).onEdit" matTooltip="Edit Row"
                            (click)="onRowEditInit(i)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn" *ngIf="getFormValue(i).onEdit" matTooltip="Cancel Row"
                            (click)="onRowEditCancel(i)">
                            <mat-icon>rotate_left</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="savefooter">
                    <th mat-footer-cell *matFooterCellDef colspan="6" class="footer">
                        <div class="OrangeButton">
                            <button mat-raised-button type="submit" (click)="onSave()" [disabled]="!hasChanges()"
                                [matTooltip]="hasChanges()? 'Save changes' : 'Save disabled'"
                                class="button-color-fix pull-right">
                                Save
                                <mat-icon style="color:white !important" class="icon-color-fix">save</mat-icon>
                            </button>
                        </div>
                    </th>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true">
                </tr>
                <tr mat-row *matRowDef="let element; let i = dataIndex;
                    columns: columnsToDisplay;" class="item" [class.alternative2]="element.value.onEdit">
                </tr>
                <tr mat-footer-row *matFooterRowDef="['savefooter']; sticky: true">
                </tr>
            </table>
        </ng-container>
    </form>
</div>