export enum EmtAllowedSoftwareReleaseType {
    Controller = 0,
    Peripheral = 1,
}

export enum EmtAllowedSoftwareReleaseValue {
    NotSet = 0,
    Allowed = 1,
    NotAllowed = 2,
}

export interface EmtAllowedSoftwareRelease {
    id: number;
    type: EmtAllowedSoftwareReleaseType;
    version: string;
    value: EmtAllowedSoftwareReleaseValue;
}

export interface EmtAllowedSoftwareReleaseLevelDto {
    environment: string;
    tenantId?: string | null;
}

export interface UpdateEmtAllowedSoftwareReleasesRequest {
    environment: string;
    tenantId?: string | null;
    softwaresReleases: {
        id: number;
        type: EmtAllowedSoftwareReleaseType;
        value: EmtAllowedSoftwareReleaseValue;
    }[];
}
