import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../../../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { CreateMeasurePopupComponent } from './create-measure/create-measure-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { SaveService } from '../../../../services/save.service';
import { FocusPlanListComponent } from './focusplan-list/focusplan-list.component';
import { MatTabGroup } from '@angular/material/tabs';
import { FocusPlanListImpactComponent } from './focusplanimpact-list/focusplanimpact-list.component';
import { FocusPlanListStatusComponent } from './focusplanstatus-list/focusplanstatus-list.component';

@Component({
    selector: 'focusplan',
    templateUrl: './focusplan.component.html',
    styleUrls: ['./focusplan.component.less']
})
export class FocusPlanComponent implements OnInit {

    label: string | undefined;
    measuresInfo!: MeasureInfo[];
    measureImpactsInfo!: MeasureImpactInfo[];
    waveId: number = -1;
    fiscalYear: number = 0;
    subscriptionWaveId: any;
    subscriptionFiscalYear: any;
    @ViewChild('tabGroup') tabGroup!: MatTabGroup;
    selectedTabIndex = 0;
    isEditModeEnabled: boolean = false;
    minMeasuresWarning = `**The minimum number of defined measures must be 3**`;

    @ViewChild('focusPlanList', { static: true }) list!: FocusPlanListComponent;
    @ViewChild('focusPlanListStatus', { static: true }) viewList!: FocusPlanListStatusComponent;
    @ViewChild('focusPlanListImpact', { static: true }) viewImpactList!: FocusPlanListImpactComponent;

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private dialogForm: MatDialog
        , private toastrService: ToastrService
        , public saveService: SaveService
        , private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.subscriptionWaveId = this.defaults.wave$.subscribe(
            (waveId: number | undefined) => {
                if (waveId) {
                    this.waveId = waveId;
                    this.loadInformation(this.waveId, true);
                    this.cdr.detectChanges(); // Manually trigger change detection
                }
            });
        this.subscriptionFiscalYear = this.defaults.fiscalYearMessageChanges$.subscribe(() =>
        {
            this.fiscalYear = this.defaults.fiscalYear;
            this.loadInformation(this.waveId, true)
        });
    }

    ngOnDestroy() {
        this.subscriptionWaveId.unsubscribe();
        this.subscriptionFiscalYear.unsubscribe();
    }

    loadInformation(waveId, reloadAll = false) {
        if (!waveId || waveId < 0) {
            this.label = `There is no assigned wave for ${this.defaults.managementid}`
            return;
        }
        //this.viewList.updatedItems = [];
        Utils.httpGetFocusPlanInformation(
            this.http
            , this.baseUrl
            , waveId
            , this
            , function (tthis: FocusPlanComponent, result: MeasureInfo[]) {
                setTimeout(() => {
                    tthis.measuresInfo = result;
                    tthis.cdr.detectChanges(); // Manually trigger change detection
                }, 50)
            }
            , function (tthis: FocusPlanComponent, error: string) {
                tthis.toastrService.error('Cannot load focus plan information for selected wave - Field Branch. Please, contact with administrator');
            }
        )

        if (reloadAll)
            Utils.httpGetFocusPlanImpact(
                this.http
                , this.baseUrl
                , this.waveId
                , this
                , function (tthis: FocusPlanComponent, result: MeasureImpactInfo[]) {
                    tthis.measureImpactsInfo = result;
                    tthis.cdr.detectChanges(); // Manually trigger change detection
                }
                , function (tthis: FocusPlanComponent, error: string) {
                    tthis.toastrService.error('Cannot load focus plan impact for selected wave - Field Branch. Please, contact with administrator');
                }
            )
    }
    onRefresh() {
        this.loadInformation(this.waveId, true)
    }

    openCreateMeasurePopup() {
        if (this.canAddNewMeasure()) {
            const dialog = this.dialogForm.open(CreateMeasurePopupComponent, {
                panelClass: ['custom-mat-dialog_v2'],
                disableClose: true,
                data: { 'waveId': this.waveId },
            })

            dialog.afterClosed().subscribe(() => {
                this.loadInformation(this.waveId, true)
            })
        }
    }

    showSaveButton() {
        return Object.keys(this.list?.clonedList).length > 0
    }

    canAddNewMeasure() {
        return this.list?.measuresInfo?.length < 5;
    }

    isMinMeasuresReached() {
        return this.list?.measuresInfo?.length > 2;
    }

    onClickEdit() {
        if (this.isEditModeEnabled === true) {
            this.isEditModeEnabled = false
            if (this.viewList.updatedItems.length > 0) {
                this.loadInformation(this.waveId)
            }
        }
        else {
            this.isEditModeEnabled = true
        }
    }

    onClickSave() {
        if (this.viewList?.updatedItems.length <= 0 && this.viewList?.updatedItems.length <= 0)
            this.isEditModeEnabled = false;
        if (this.viewList?.editedStatusComments.length > 0) {
            Utils.httpUpsertActionItemlist(
                this.http
                , this.baseUrl
                , this.viewList?.editedStatusComments
                , this
                , function (tthis: FocusPlanComponent, result: any) {
                    if (result) {
                        tthis.viewList.editedStatusComments = [];
                        tthis.isEditModeEnabled = false;
                        tthis.toastrService.success('Action Item Status Comments were saved successfully!');
                        tthis.loadInformation(tthis.waveId)
                    }
                    else
                        tthis.toastrService.error('Action Item Status Comments could not be saved. Please, contact with administrator');
                },
                null
            );
        }
        if (this.viewList?.updatedItems.length > 0)
            Utils.httpUpsertActionItemStatuses(
                this.http,
                this.baseUrl,
                this.viewList.updatedItems,
                this,
                function (tthis: FocusPlanComponent, result: any) {
                    if (result) {
                        tthis.viewList.updatedItems = [];
                        tthis.isEditModeEnabled = false;
                        tthis.toastrService.success('Action Item Statuses were saved successfully!');
                        tthis.loadInformation(tthis.waveId)
                    }
                    else
                        tthis.toastrService.error('Action Item Statuses could not be saved. Please, contact with administrator');
                },
                null
            )
        if (this.viewImpactList?.updatedItems.length > 0)
            Utils.httpUpsertFinancialImpact(
                this.http,
                this.baseUrl,
                this.waveId,
                this.viewImpactList.updatedItems,
                this,
                function (tthis: FocusPlanComponent, result: any) {
                    if (result) {
                        tthis.viewImpactList.updatedItems = [];
                        tthis.isEditModeEnabled = false;
                        tthis.toastrService.success('Targets were saved successfully!');
                        tthis.loadInformation(tthis.waveId, true)
                    }
                    else
                        tthis.toastrService.error('Targets could not be saved. Please, contact with administrator');
                },
                null
            )
    }
}
