import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DefaultValuesService } from 'src/app/services/defaultvalues.service';
import { EquipmentManagenementService } from 'src/app/services/equipmentmanagement.service';

@Component({
  selector: 'add-software-release-level',
  templateUrl: './add-software-release-level.component.html',
  styleUrls: ['../../../../../style/custom-mat-dialog.component.less']
})
export class AddSoftwareReleaseLevelComponent implements OnInit {
  public environments: SelectList[] = [];
  public tenants: SelectList[] = [];
  public formGroup: FormGroup;
  @Output()
  public onLevelInitializedTrigger = new EventEmitter<EquipmentConfigurationLevel>();

  constructor(
    private dialogRef: MatDialogRef<AddSoftwareReleaseLevelComponent>,
    private defaults: DefaultValuesService,
    private emtService: EquipmentManagenementService,
    private toastService: ToastrService,
    formBuilder: FormBuilder) {
    this.formGroup = formBuilder.group({
      environment: [null, Validators.required],
      tenant: [null]
    });
  }

  public ngOnInit() {
    this.environments = this.defaults.environments
      .map(x => ({ value: x, text: x.toUpperCase() }));
  }

  public closeDialog(event: any) {
    event.preventDefault();
    this.dialogRef.close();
  }

  public onEnvironmentChanged($event: any) {
    this.tenants = this.defaults.tenants
      .filter(x => x.Environment === $event.value)
      .map(x => ({
        value: x.Tenant,
        text: x.Tenant + ' (' + x.CountryName + ')'
      }));
  }

  public onSubmit() {
    if (this.formGroup.valid) {
      console.log('Form Submitted', this.formGroup.value);
      this.saveLevel();
    } else {
      console.log('Form is invalid');
    }
  }

  private saveLevel() {
    const level = {
      environment: this.formGroup.get('environment')!.value,
      tenantId: this.formGroup.get('tenant')?.value,
    };

    this.emtService.addSoftwareReleaseLevel(level)
      .subscribe({
        next: () => {
          this.onLevelInitializedTrigger.emit(level);
          this.dialogRef.close();
        },
        error: () => {
          this.toastService.error("Unable to save new level.");
        }
      });
  }
}
