import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SaveService } from '../../../../../services/save.service';
import { CreateMeasurePopupComponent } from '../create-measure/create-measure-popup.component';
import { CreateActionItemPopupComponent } from '../create-actionitem/create-action-item-popup.component';
import { DialogService } from '../../../../../services/dialog.service';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../../../../utils/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'focusplanimpact-list',
    templateUrl: './focusplanimpact-list.component.html',
    styleUrls: ['./focusplanimpact-list.component.less',
        '../../../../../style/new-generic-styles.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})
export class FocusPlanListImpactComponent implements OnInit {

    @Input('measureImpactsInfo') measureImpact!: MeasureImpactInfo[];
    @Input('waveId') waveId!: number;
    @Input('isEditModeEnabled') isEditModeEnabled!: boolean;
    @Input('fiscalYear') fiscalYear!: number;
    @Output() refresh = new EventEmitter<string>();
    updatedItems: any[] = [];
    fiscalYearDisplay: string = "";
    columnsToDisplay = ['arrow', 'ItemNo', 'Description', 'Kpi', 'KpiDescription', 'BreakdownSelector', 'PeriodSelector'];
    subcolumnsToDisplayTemplate = ['item', 'P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'P7', 'P8', 'P9', 'P10', 'P11', 'P12'];
    periods: any[] = [];

    public dataSource = new MatTableDataSource<MeasureImpactInfo>();

    expandedElements = new Set();

    dialogRef: any;

    onRefresh: any;

    selectedBreakdown: number = -1;

    selectedPeriod: number = 0;

    constructor(
        private saveService: SaveService
        , private dialogForm: MatDialog
        , private dialog: DialogService
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private toastrService: ToastrService
    ) { }

    ngOnInit(): void {

    }

    ngOnChanges(changes: any) {
        this.fiscalYearDisplay = (this.fiscalYear - 1) + "/" + this.fiscalYear;

        this.periods = [{ id: 0, name: 'All periods', }, { id: 1, name: 'P1 (OCT - ' + (this.fiscalYear -1) + ')', }, { id: 2, name: 'P2 (NOV - ' + (this.fiscalYear - 1) + ')', },
            { id: 3, name: 'P3 (DEC - ' + (this.fiscalYear - 1) + ')', }, { id: 4, name: 'P4  (JAN - ' + this.fiscalYear + ')', }, { id: 5, name: 'P5 (FEB - ' + this.fiscalYear + ')', },
            { id: 6, name: 'P6 (MAR - ' + this.fiscalYear + ')', }, { id: 7, name: 'P7 (APR - ' + this.fiscalYear + ')', }, { id: 8, name: 'P8 (MAY - ' + this.fiscalYear + ')', },
            { id: 9, name: 'P9 (JUN - ' + this.fiscalYear + ')', }, { id: 10, name: 'P10 (JUL - ' + this.fiscalYear + ')', }, { id: 11, name: 'P11 (AUG - ' + this.fiscalYear + ')', },
            { id: 12, name: 'P12 (SEP - ' + this.fiscalYear + ')', }];

        this.measureImpact?.forEach((x, i) => {
            x.BreakdownSelector = []
            x.Subtables = []
            var counter = 0;
            x.Breakdowns.forEach(y => {
                x.Subtables.push(
                    [
                        { item: 'Actual PY', ...y.ActualPY },
                        { item: 'Actual', ...y.Actual },
                        { item: 'Budget', ...y.Budget },
                        { item: 'Target %', ...y.Target },
                        { item: 'Target',},
                        { item: 'Delta Target', ...this.subtractNestedObjects(y.Budget, y.Actual) }
                    ]
                ),
                    x.BreakdownSelector.push({ id: counter++, label: y.Breakdown }),
                    x.SelectedBreakdown = 0
                   
            }),
            x.PeriodSelector = this.periods
            x.SelectedPeriod = 0
            x.ItemNo = ++i
            x.subColumnsToDisplay = this.subcolumnsToDisplayTemplate
        })
        this.dataSource.data = this.measureImpact

    }

    pushPopElement(measureId: number) {
        if (this.expandedElements.has(measureId)) {
            this.expandedElements.delete(measureId);
        }
        else {
            this.expandedElements.add(measureId);
        }
    }
    checkExpanded(measureId: number): boolean {
        return this.expandedElements.has(measureId);
    }

    isEditableFieldEnabled(item: string) {
        return item === "Target %" && this.isEditModeEnabled === true
    }

    getIsEditModeEnabled() {
        return this.isEditModeEnabled === true
    }

    onChange(measure: MeasureImpactInfo, period: number, measureTargetId: number, $event: any) {
        const breakdown = this.measureImpact.filter(x => x.KpiDefinitionId === measure.KpiDefinitionId)[0].Breakdowns[measure.SelectedBreakdown]

        const index = this.updatedItems.findIndex(
            (obj) =>
                obj.KpiBreakdown1Id === breakdown.KpiBreakdown1Id &&
                obj.KpiBreakdown2Id === breakdown.KpiBreakdown2Id &&
                obj.KpiProductHierarchyId === breakdown.KpiProductHierarchyId &&
                obj.KpiMovementTypeId === breakdown.KpiMovementTypeId &&
                obj.KpiDefinitionId === measure.KpiDefinitionId &&
                obj.Period === period)

        if (index >= 0) {
            this.updatedItems.splice(index, 1)
        }

        if ($event !== null)
            this.updatedItems.push({
                KpiBreakdown1Id: breakdown.KpiBreakdown1Id,
                KpiBreakdown2Id: breakdown.KpiBreakdown2Id,
                KpiMovementTypeId: breakdown.KpiProductHierarchyId,
                KpiProductHierarchyId: breakdown.KpiProductHierarchyId,
                KpiDefinitionId: measure.KpiDefinitionId,
                Period: period,
                MeasureId: measure.MeasureId,
                MeasureTargetId: measureTargetId,
                Value: $event
            })
    }

    onSelect($event: any, kpiDefinitionId: number) {
        const measure = this.dataSource.data.filter(x => x.KpiDefinitionId === kpiDefinitionId)[0]
        measure.SelectedBreakdown = $event.id
    }

    onSelectPeriod($event: any, kpiDefinitionId: number)
    {
        const measure = this.dataSource.data.filter(x => x.KpiDefinitionId === kpiDefinitionId)[0]
        if ($event.id == 0) {
            measure.subColumnsToDisplay = this.subcolumnsToDisplayTemplate;
        }
        else
        {
            measure.subColumnsToDisplay = ['item', 'P' + $event.id]
        }
    }

    getTarget(subtables: any[], selectedBreakdown: number, period: string) {
        return (subtables[selectedBreakdown][3][period] * subtables[selectedBreakdown][2][period]) / 100
    }


    subtractNestedObjects(obj1: MeasurePeriodValue, obj2: MeasurePeriodValue): MeasurePeriodValue {
        const result: MeasurePeriodValue = {
            P1: 0,
            P2: 0,
            P3: 0,
            P4: 0,
            P5: 0,
            P6: 0,
            P7: 0,
            P8: 0,
            P9: 0,
            P10: 0,
            P11: 0,
            P12: 0
        };
        for (const key in obj1) {
            if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
                result[key] = obj1[key] - obj2[key];
            }
        }
        return result;
    }
    showDropdown(items) {
        if (items.length == 1) {
            if (items[0].id == 0 && !items[0].label)
            {
                return false
            }
        }
        return true
    }
    validateInput(item: string, event: any): void {
        if (item === 'Target %')
        {
            const input = event.target;
            if (input.value < 0) {
                input.value = 0;
            } else if (input.value > 100) {
                input.value = 100;
            }
        }
    }
}