<mat-accordion multi class="expand-collapse-panel">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
        </mat-expansion-panel-header>
        <form autocomplete="off" [formGroup]="form" id="filterContracts">
            <table>
                <tr>
                    <td>
                        <mat-form-field class="search-input">
                            <mat-label>Contract Number</mat-label>
                            <input placeholder="Contract Number"
                                   title="Contract Number"
                                   matInput
                                   [matChipInputFor]="chipListContract"
                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                   [matChipInputAddOnBlur]="addOnBlur"
                                   (matChipInputTokenEnd)="applyFilter($event, 'contractnumbers')">
                        </mat-form-field>
                        <mat-chip-list #chipListContract>
                            <mat-chip *ngFor="let item of contractNumbers" [selectable]="selectable"
                                      [removable]="removable" (removed)="remove(item, 'contractnumbers')"
                                      matTooltip="{{item}}">
                                <span class="chip-text">{{item}}</span>
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </td>
                    <td>
                        <mat-form-field class="search-input">
                            <mat-label>Unit Id</mat-label>
                            <input placeholder="Unit Id"
                                   title="Unit Id"
                                   matInput
                                   [matChipInputFor]="chipListUnits"
                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                   [matChipInputAddOnBlur]="addOnBlur"
                                   (matChipInputTokenEnd)="applyFilter($event, 'unitids')">
                        </mat-form-field>
                        <mat-chip-list #chipListUnits>
                            <mat-chip *ngFor="let item of unitIds" [selectable]="selectable"
                                      [removable]="removable" (removed)="remove(item, 'unitids')"
                                      matTooltip="{{item}}">
                                <span class="chip-text">{{item}}</span>
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </td>
                    <td>
                        <mat-form-field class="search-input">
                            <mat-label>Customer Id</mat-label>
                            <input placeholder="Customer Id"
                                   title="Customer Id"
                                   matInput
                                   [matChipInputFor]="chipListCustomer"
                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                   [matChipInputAddOnBlur]="addOnBlur"
                                   (matChipInputTokenEnd)="applyFilter($event, 'customerids')">
                        </mat-form-field>
                        <mat-chip-list #chipListCustomer>
                            <mat-chip *ngFor="let item of customerIds" [selectable]="selectable"
                                      [removable]="removable" (removed)="remove(item, 'customerids')"
                                      matTooltip="{{item}}">                             
                                <span class="chip-text">{{item}}</span>
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </td>
                    <td>
                        <mat-form-field class="search-input">
                            <mat-label>Contract Line</mat-label>
                            <input matInput
                                   title="Contract Line"
                                   placeholder="Contract Line"
                                   [matChipInputFor]="chipListContractLine"
                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                   [matChipInputAddOnBlur]="addOnBlur"
                                   (matChipInputTokenEnd)="applyFilter($event, 'contractlines')">
                        </mat-form-field>
                        <mat-chip-list #chipListContractLine>
                            <mat-chip *ngFor="let item of contractLines" [selectable]="selectable"
                                      [removable]="removable" (removed)="remove(item, 'contractlines')"
                                      matTooltip="{{item}}">
                                <span class="chip-text">{{item}}</span>
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </td>
                    <td>
                        <mat-form-field class="search-input">
                            <mat-label>Contract Line Locale</mat-label>
                            <input matInput
                                   title="Contract Line Locale"
                                   placeholder="Contract Line Locale"
                                   [matChipInputFor]="chipListContractLineLocale"
                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                   [matChipInputAddOnBlur]="addOnBlur"
                                   (matChipInputTokenEnd)="applyFilter($event, 'contractLinesLocale')">
                        </mat-form-field>
                        <mat-chip-list #chipListContractLineLocale>
                            <mat-chip *ngFor="let item of contractLinesLocale" [selectable]="selectable"
                                      [removable]="removable" (removed)="remove(item, 'contractLinesLocale')"
                                      matTooltip="{{item}}">
                                <span class="chip-text">{{item}}</span>
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </td>
                    <td>
                        <mat-form-field class="search-input">
                            <mat-label>Contract Type</mat-label>
                            <input matInput
                                   title="Contract Type"
                                   placeholder="Contract Type"
                                   [matChipInputFor]="chipListContractType"
                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                   [matChipInputAddOnBlur]="addOnBlur"
                                   (matChipInputTokenEnd)="applyFilter($event, 'contractTypes')">
                        </mat-form-field>
                        <mat-chip-list #chipListContractType>
                            <mat-chip *ngFor="let item of contractTypes" [selectable]="selectable"
                                      [removable]="removable" (removed)="remove(item, 'contractTypes')"
                                      matTooltip="{{item}}">
                                <span class="chip-text">{{item}}</span>
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </td>
                    <td>
                        <mat-form-field class="search-input">
                            <mat-label>Contract Type Locale</mat-label>
                            <input matInput
                                   title="Contract Type Locale"
                                   placeholder="Contract Type Locale"
                                   [matChipInputFor]="chipListContractTypeLocale"
                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                   [matChipInputAddOnBlur]="addOnBlur"
                                   (matChipInputTokenEnd)="applyFilter($event, 'contractTypesLocale')">
                        </mat-form-field>
                        <mat-chip-list #chipListContractTypeLocale>
                            <mat-chip *ngFor="let item of contractTypesLocale" [selectable]="selectable"
                                      [removable]="removable" (removed)="remove(item, 'contractTypesLocale')"
                                      matTooltip="{{item}}">
                                <span class="chip-text">{{item}}</span>
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </td>
                    <td>
                        <button type="button" class="btn mat-flat-button btn-primary" (click)="onFilter()">
                            {{ changeTypeButton() ? 'Reset' : 'Filter' }}
                        </button>
                    </td>
                </tr>
                <tr *ngIf="anyFilterApplied">
                    <td colspan="8">
                        <div class="mat-slide-toggle-container">
                            <span style="margin-right:5px" [ngClass]="{'disabledText': showActiveToggle.checked, 'enabledText': !showActiveToggle.checked}">Show All</span>
                            <mat-slide-toggle class="custom-slide-toggle" #showActiveToggle formControlName="showOnlyActive" (change)="toggleActive($event)"></mat-slide-toggle>
                            <span style="margin-left:5px" [ngClass]="{'disabledText': !showActiveToggle.checked, 'enabledText': showActiveToggle.checked}">Show Active</span>
                        </div>
                    </td>
                </tr>
            </table>
        </form>
    </mat-expansion-panel>
</mat-accordion>