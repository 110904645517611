import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnDestroy, OnInit, Output, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms"
import { ActivatedRoute, Params } from '@angular/router';
import { UMPAction, UMPCategory, Utils } from '../../../../utils/utils';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomDialogService } from '../../../../services/customdialog.service';
import { DeleteUserDialogue } from './deleteuserdialogue/deleteuserdialogue.component';
import { AddGroupDialogue } from './addgroupdialogue/addgroupdialogue.component';
import { UMPBranchHierarchy } from './umpbranchhierarchy/umpbranchhierarchy.component'
import { SpinnerOverlayService } from '../../../../services/spinner-overlay.service';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { ComponentTool } from '../../../../interfaces/componenttool';
import { CacheInfoDialogue } from './cacheinfodialogue/cacheinfodialogue.component';
import { SaveService } from '../../../../services/save.service';
import { delay } from 'rxjs/operators';
import { ResetPwdDialogue } from './resetpwddialogue/resetpwddialogue.component';
import { UserHistoryDialogue } from './userhistorydialogue/userhistorydialogue.component';
import { DialogService } from '../../../../services/dialog.service';
import { environment } from '../../../../../environments/environment'


@Component({
    selector: "edituser",
    templateUrl: './edituser.component.html',
    styleUrls: ['./edituser.component.less', '../../../../style/new-generic-styles.component.less'],
    providers: [UMPBranchHierarchy],
    encapsulation: ViewEncapsulation.None
})
export class EditUserComponent implements OnInit, OnDestroy {
    public editUserForm: FormGroup;
    public userFormCopy: any;

    public isProductionEnvironment = environment.production;
    public userName: String = '';
    options: any[] = [{ _id: '1', status: 'waiting' },
    { _id: '2', status: 'open' },
    { _id: '3', status: 'in_progress' },
    { _id: '4', status: 'close' }];
    selectedSecTenString = '';
    userId = ''

    Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
    License_Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API

    errorResponse: any;
    isCallSucceed: number = 0;
    isCallSucceedResInv: number = 0;


    licenseSearchValue: string = '';


    filteredLicenseItems: any[] = [];

    roleSearchValue: string = '';

    filteredDirectRoleItems: any[] = [];
    filteredInDirRoleItems: any[] = [];
    fullDirectRoleItems: any[] = [];
    fullInDirRoleItems: any[] = [];

    userLicenses: any[] = [];
    userRoles: any[] = [];

    dialogRef: MatDialogRef<ResetPwdDialogue, any> | undefined
    deldialogRef: MatDialogRef<DeleteUserDialogue, any> | undefined



    addgrpdialogRef: MatDialogRef<AddGroupDialogue, any> | undefined
    cachegroupDialogRef: MatDialogRef<CacheInfoDialogue, any> | undefined

    userhistorydialogRef: MatDialogRef<UserHistoryDialogue, any> | undefined
    textIconItemArray: TextIconItem[] = [];

    AllAzDevProj = [];
    UserAzDevProj = [];
    RemainAzDevProj = [];

    reload = -1;
    resetAllCommand = true;
    disableSaveButton = true;

    selectedParentId: string | null = null;



    userDetails: UserDetails = {
        active: false,
        displayName: '',
        email: '',
        firstName: '',
        inviteUser: false,
        mersyAccessReport: false,
        marketQueryAccessReport: false,
        isEditable: false,
        isExternalUser: false,
        isPendingAcceptance: false,
        lastName: '',
        secondaryTenants: [],
        tenant: '',
        userEmail: '',
        userPrincipalName: '',
        secondaryTenantsSelectList: [],
        tenantsSelectList: [],
        companyName: ''
    }

    componentToolEnum: typeof ComponentTool = ComponentTool;
    showSearchAddon: boolean = false;
    stickyPinned = false;

    secTenantSet = new Set<string>();
    initialPrimTen = "";
    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private http: HttpClient,
        private toastrService: ToastrService,
        private dialogForm: MatDialog,
        private umpbranchheir: UMPBranchHierarchy,
        @Inject('BASE_URL') private baseUrl: string,
        private spinnerOverlayService: SpinnerOverlayService,
        public defaults: DefaultValuesService,
        private customDialog: CustomDialogService,
        public saveService: SaveService,
        public topDetlRef: ElementRef,
        private groupmgmtresponseDialog: DialogService

    ) {
        this.route.params.subscribe(
            (params: Params) => {
                this.constructorfunc();
            });

        this.editUserForm = this.fb.group(
            {
                firstName: [''],
                email: [''],
                lastName: [''],
                displayName: ['', Validators.required],
                tenant: [null],
                secondaryTenants: [null],
                active: [false],
                inviteUser: [false],
                mersyAccessReport: [false],
                marketQueryAccessReport: [false],
                isexternaluser: [null],
                companyName: [''],
            }, {
        });
        this.groupmgmtresponseDialog.showTypeIcon = false;
    }

    constructorfunc() {

        this.userDetails.userPrincipalName = '';
        this.userDetails.isPendingAcceptance = false;
        this.updateLicenseResults();
        this.initialPrimTen = '';

        this.editUserForm = this.fb.group(
            {
                firstName: [''],
                email: [''],
                lastName: [''],
                displayName: ['', Validators.required],
                tenant: [null],
                secondaryTenants: [null],
                active: [false],
                inviteUser: [false],
                mersyAccessReport: [false],
                marketQueryAccessReport: [false],
                isexternaluser: [null],
                companyName: [''],
            }, {
        });

        this.userId = this.route.snapshot.paramMap.get('userId') || '';
        this.funcGetUserDetails();

        if (this.defaults.componentTool === this.componentToolEnum.Rulebook && !this.defaults.isDataAnalytStage()) {
            this.funcGetUserLicenses();
        }
    }
    ngOnInit() {
        this.saveService.showSaveButton();
    }

    ngOnDestroy(): void {

        this.saveService.removeListener(this)
    }

    onStickyTrigger(): void {
        var el2
        const el = this.topDetlRef.nativeElement.querySelector('.stickyTopDetls');
        this.defaults.componentTool === this.componentToolEnum.Rulebook ? el2 = this.topDetlRef.nativeElement.querySelector('.stickyTopBtns') : el2 = null;
        const observer = new IntersectionObserver(
            ([e]) => {
                e.target.classList.toggle('is-pinned', e.intersectionRatio < 1)
                el2?.classList.toggle("is-pinned", e.intersectionRatio < 1);
                e.intersectionRatio < 1 ? this.stickyPinned = true : this.stickyPinned = false;
            },
            { threshold: [1] }
        );

        observer.observe(el);
    }

    onSecTenChange() {
        this.selectedSecTenString = this.editUserForm.controls['secondaryTenants'].value.map(item => item).join(', ');
    }

    async updateLicenseResults() {
        this.filteredLicenseItems = this.searchByValue(this.userLicenses, "skuName", this.licenseSearchValue);
    }

    searchByValue(items: any, filterProperty: string, query: string): any {

        return items.filter(item => {
            if (query.trim() === '') {
                return true;
            } else {
                return item[filterProperty].toLowerCase().includes(query.trim().toLocaleLowerCase())
            }
        })
    }

    onSave() {


        this.disableSaveButton = true;
        this.isCallSucceed = 0;
        var formvalues = this.editUserForm.getRawValue();

        //formvalues.secondaryTenants = this.removePrimaryTenant(formvalues.tenant, formvalues.secondaryTenants);
        formvalues.secondaryTenants = this.removePrimaryTenant(formvalues.tenant, Array.from(this.secTenantSet.values()));


        Utils.httpUpdateUser(
            this.http
            , this.baseUrl
            , formvalues
            , this.userId
            , UMPCategory.UserDetails
            , UMPAction.Update
            , this
            , function (tthis: EditUserComponent) {
                tthis.resetInitialFilters()
                tthis.reload = Math.random()
                tthis.funcNotify();
                tthis.funcGetUserDetails();
                tthis.umpbranchheir.ngOnInit();
                //tthis.saveService.addListener(tthis)
                //tthis.saveService.showSaveButton();
                tthis.secTenantSet?.clear()
            }
        )



    }
    removePrimaryTenant(primaryTen: string, secTen: string[]): string[] {
        return secTen.filter(item => item !== primaryTen);
    }




    funcGetUserDetails() {
        this.disableSaveButton = true;
        Utils.httpGetUserDetails(
            this.http
            , this.baseUrl
            , this.userId
            , this
            , async function (tthis: EditUserComponent, data: UserDetails) {
                tthis.userDetails = data;
                tthis.editUserForm.controls['firstName'].setValue(tthis.userDetails.firstName)
                tthis.editUserForm.controls['email'].setValue(tthis.userDetails.email)
                tthis.editUserForm.controls['lastName'].setValue(tthis.userDetails.lastName)
                tthis.editUserForm.controls['displayName'].setValue(tthis.userDetails.displayName)
                tthis.editUserForm.controls['tenant'].setValue(tthis.userDetails.tenant)
                tthis.editUserForm.controls['secondaryTenants'].setValue(tthis.userDetails.secondaryTenants)
                tthis.editUserForm.controls['active'].setValue(tthis.userDetails.active)
                tthis.editUserForm.controls['inviteUser'].setValue(tthis.userDetails.inviteUser)
                tthis.editUserForm.controls['mersyAccessReport'].setValue(tthis.userDetails.mersyAccessReport)
                tthis.editUserForm.controls['marketQueryAccessReport'].setValue(tthis.userDetails.marketQueryAccessReport)
                tthis.editUserForm.controls['isexternaluser'].setValue(tthis.userDetails.isExternalUser)
                tthis.editUserForm.controls['companyName'].setValue(tthis.userDetails.companyName)
                tthis.userFormCopy = tthis.editUserForm.getRawValue();
                tthis.initialPrimTen = tthis.editUserForm.controls['tenant'].value;
                tthis.disableSaveButton = true;
                tthis.subscribeChanges();
                if (!tthis.defaults.isDataAnalytStage()) {
                    tthis.selectAllForDropdownItems(tthis.userDetails.secondaryTenantsSelectList);
                    tthis.secTenantSet = new Set(tthis.userDetails.secondaryTenants);
                }
                setTimeout(() => {
                    tthis.onStickyTrigger();
                }, 1000);

            }, this.defaults.isDataAnalytStage()
        )
    }
    selectAllForDropdownItems(items) {

        let allSelect = items => {
            items.forEach(element => {
                element['selectedAllGroup'] = 'selectedAllGroup';
            });
        };

        allSelect(items);
    }
    subscribeChanges() {
        this.editUserForm.valueChanges.subscribe(changes => {
            this.wasFormChanged(changes);
        });
    }

    wasFormChanged(currentValue) {
        const fields = Object.keys(this.editUserForm.controls);
        const userFormCopyJson = this.userFormCopy

        for (let i = 0; i < fields.length; i++) {
            const fieldName = fields[i];
            if (userFormCopyJson[fieldName] !== currentValue[fieldName]) {
                this.disableSaveButton = false;
                this.saveService.addListener(this)
                return;
            }
        }
    }

    funcGetUserLicenses() {
        this.License_Response_State = -2;
        Utils.httpGetUserLicenses(
            this.http
            , this.baseUrl
            , this.userId
            , this
            , function (tthis: EditUserComponent, data: any) {
                tthis.userLicenses = data.userLicenses
                tthis.filteredLicenseItems = tthis.userLicenses
            }
        )
    }
    funcNotifyResendInvite() {
        if (this.isCallSucceedResInv === 1) {
            this.toastrService.success('User invitation request sent successfully !', 'User Invitation Request!');
        }
        else if (this.isCallSucceedResInv === -1) {
            this.toastrService.error('User invitation request not sent successfully !', 'User Invitation Request!');
        }
    }
    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('User Details update request sent successfully !', 'User Edit Request!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('User Details update request not sent successfully !', 'User Edit Request!');
        }
    }


    handleresetbutton(event: any) {
        event.preventDefault();

        this.dialogRef = this.dialogForm.open(ResetPwdDialogue, {
            //minHeight: '340px',
            //minWidth: '600px',
            data: { userId: this.userId, useremail: this.userDetails.userEmail }
        });
    }
    handledelbutton(event: any) {
        event.preventDefault();

        this.deldialogRef = this.dialogForm.open(DeleteUserDialogue, {
            data: { userId: this.userId, useremail: this.userDetails.userEmail }
        });
    }
    handleResendIvite() {
        this.isCallSucceedResInv = 0;
        Utils.httpResentInvitation(
            this.http
            , this.baseUrl
            , { DisplayName: this.userDetails.displayName, UserEmailAddress: this.userDetails.userEmail, SendInvitationMessage: true }
            , this
            , function (tthis: EditUserComponent, data: any) {
                tthis.funcNotifyResendInvite();

            }
        )

    }
    onOpenSecondaryTenants() {
        setTimeout(() => {
            const scrollContainer = document.querySelector('.ng-dropdown-panel-items');
            if (scrollContainer) {
                scrollContainer.scrollTop = 0;
            }
        }, 0);
    }
    //onParentItemClick(id) {
    //    this.selectedParentId = id;
    //}
    //isChildHighlighted(childItem: any): boolean {
    //    if (!this.selectedParentId) {
    //        return false; // No parent item is selected, so no need to highlight any child
    //    }

    //    return childItem.parentIds.includes(this.selectedParentId);
    //}

    resetInitialFilters() {
        // Reseting initial filters when user modify his account
        if (this.userDetails.userEmail.toUpperCase() === this.defaults.session?.UserUPN.toUpperCase())
            this.defaults.umpFilters = {
                cdpList: [],
                nodeId: [],
                nodes: [],
                page: 0,
                pageSize: 12,
                personaId: [],
                personas: [],
                tenant: [],
                tenants: [],
                userPrincipalName: ''
            };

    }

    showSearchAddonMethod() {
        this.showSearchAddon = !this.showSearchAddon;
    }

    clearCacheUser($event: any) {
        $event.preventDefault()
        this.customDialog.openConfirm({
            title: "Clear Cache user",
            message: `Process will reset cache information for that user. Do you want to continue?`,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed) {
                Utils.httpClearCacheByUser(
                    this.http
                    , this.baseUrl
                    , this.userId
                    , this
                    , function (tthis: EditUserComponent) {
                        if (tthis.isCallSucceed === 1) {
                            tthis.toastrService.success('User cache cleared successfully !', 'User Cache Request!');
                        }
                        else if (tthis.isCallSucceed === -1) {
                            tthis.toastrService.error('User cache did not clear successfully !', 'User Cache Request!');
                        }
                    }
                    , this.defaults.isDataAnalytStage()
                )
            }
        })
    }

    getUserCache($event: any) {
        $event.preventDefault();
        this.cachegroupDialogRef = this.dialogForm.open(CacheInfoDialogue, {
            height: '800px',
            width: '800px',
            data: { userId: this.userId }
        });
        this.cachegroupDialogRef.afterClosed().subscribe(() => {

        });
    }


    updateTenSet(eventData: { selTen: string[], checked: boolean }) {
        eventData.selTen.forEach((ten) => { eventData.checked ? this.secTenantSet.add(ten) : this.secTenantSet.delete(ten) })
        this.disableSaveButton = false;
        this.saveService.addListener(this)
    }
    onPrimTenChange(newTen) {
        this.secTenantSet.add(this.initialPrimTen);
        this.secTenantSet.delete(newTen);
    }

    checkUserHistory($event: any) {
        $event.preventDefault();
        this.userhistorydialogRef = this.dialogForm.open(UserHistoryDialogue, {
            height: '525px',
            width: '1500px',
            data: { userId: this.userId }
        });
        this.userhistorydialogRef.afterClosed().subscribe(() => {
        });
    }

    public groupMgmtCallback = (data: GroupUserManagementResponse) => {
        if (data) {
            let show = this.textIconItemArray.length == 0;
            Object.keys(data.isSuccessfullyAppliedToGroup).forEach(key => {
                let item: TextIconItem = {
                    text: key,
                    matIcon: data.isSuccessfullyAppliedToGroup[key] ? 'check' : 'cancel',
                    iconClass: data.isSuccessfullyAppliedToGroup[key] ? 'green bottom' : 'red bottom'
                };
                this.textIconItemArray.push(item);
            });
            this.groupmgmtresponseDialog.text_icon_items = this.textIconItemArray;
            if (show) {
                this.groupmgmtresponseDialog.tthis = this;
                this.groupmgmtresponseDialog.showSuccessDialog("", `Group action result status:`);
            }
        }
    };

    onBack(ref: any) {
        this.textIconItemArray = [] as TextIconItem[];
        this.groupmgmtresponseDialog.text_icon_items = this.textIconItemArray;
        this.groupmgmtresponseDialog.extraClasses = undefined;

    }

    onMersyDirectClick(event: any, type: number) {
        Utils.httpEnableDisablePowerBiUser(
            this.http
            , this.baseUrl
            , this.userId
            , type
            , event.checked
            , this
            , function (tthis: EditUserComponent) {
                tthis.toastrService.success(`Group was ${event === true ? 'assigned' : 'unassigned'} successfully !`, 'Group assigment Request!');
            }
        )
    }
}