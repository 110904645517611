import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HistoryLeverDialog } from '../history-lever-dialog/history-lever-dialog.component';


@Component({
    selector: 'action-priorization-list',
    templateUrl: './action-priorization-list.component.html',
    styleUrls: ['./action-priorization-list.component.less']
})
export class ActionPriorizationListComponent implements OnInit {
    @Input('childData') childData: any[] | undefined;
    @Input('priorities') priorities: Priority[] = [];
    @Input('isEditModeEnabled') isEditModeEnabled: boolean = false;

    updatedItems: number[] = [];

    columnsToDisplay = ['ExcellenceLever', 'Action', 'Priority', 'Comments'];

    public dataSource = new MatTableDataSource<any>();

    isOpenTextAreaOverlay = false;

    dialogRef: any;
    selectedPriority: string | number = 'all';
    constructor(
        private dialogForm: MatDialog
    ) {
        this.dataSource.filterPredicate = (data: any, filter: string) => {
            if (filter === 'all') {
                return true; 
            }
            return data.PriorityId === parseInt(filter, 10);
        };
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: any) {
        if (changes.childData && this.childData) {
            this.dataSource.data = this.childData.filter(item => item.VisibleImprovementLevers === true)
        }
    }

    onChange(waveExcellenceLeverConfigId: number) {
        if (!this.updatedItems.includes(waveExcellenceLeverConfigId))
            this.updatedItems.push(waveExcellenceLeverConfigId);
    }

    onTextFieldChange(comment: string, textAreaId: string, lever: any) {
        if (lever) {
            let type = textAreaId.split('_')[0];
            switch (type) {
                case 'DiscussedComments':
                    lever.DiscussedComments = comment;
                    break;
            }
            this.onChange(lever.WaveExcellenceLeverConfigId);
        }
    }
    onPriorityFilterChange(id: string | number) {
        this.dataSource.filter = (id === 'all' ? id : id.toString());
    }
}
