import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Utils } from '../../../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { AssessmentListComponent } from './assessment-list/assessment-list.component';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { ActionPriorizationListComponent } from './action-priorization-list/action-priorization-list.component';
import { Router } from '@angular/router';

@Component({
    selector: 'assessment',
    templateUrl: './assessment.component.html',
    styleUrls: ['./assessment.component.less', '../../../../style/container-tab-list.component.less', '../../../../style/new-generic-styles.component.less']
})
export class AssessmentComponent implements OnInit, OnDestroy {

    @ViewChild(AssessmentListComponent) assessmentViewList: AssessmentListComponent = {} as AssessmentListComponent;
    @ViewChild(ActionPriorizationListComponent) prioritiesViewList: ActionPriorizationListComponent = {} as ActionPriorizationListComponent;
    private readonly _destroying$ = new Subject<void>();

    assessmentInfo: Assessment | undefined;
    childData: any[] = [];
    stages: ExcellenceStage[] = [];
    priorities: Priority[] = [];
    contacts: BranchContact[] = [];
    subscriptionWaveId: any;
    subscriptions: any[] = [];

    label: string = '';
    waveId: number = -1;
    isEditModeEnabled: boolean = false;
    showImprovementLeversTab: boolean = false;
    isExcelModeEnabled = false;

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private toastrService: ToastrService
        , private router: Router
        , private datePipe: DatePipe) { }

    ngOnInit(): void {
        this.getExcellenceStages();
        this.getPriorities();
        this.getBranchContacts();
        this.subscriptionWaveId = this.defaults.wave$.subscribe(
            (waveId: number | undefined) => {
                if (waveId) {
                    this.waveId = waveId;
                    this.assessmentViewList.clearSelectedFactorValues();
                    this.loadInformation(this.waveId)
                }
            })
        if (this.defaults.waveId)
            this.loadInformation(this.defaults.waveId)
    }

    // unsubscribe to events when component is destroyed
    ngOnDestroy(): void {
        this.subscriptionWaveId.unsubscribe();
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }


    loadInformation(waveId) {
        if (!waveId || waveId < 0) {
            this.label = `There is no assigned wave for ${this.defaults.managementid}`
            this.assessmentInfo = undefined
            this.childData = [];
            return;
        }
        this.isEditModeEnabled = this.showImprovementLeversTab = false;
        this.assessmentViewList.updatedItems = [];
        if (this.prioritiesViewList)
            this.prioritiesViewList.updatedItems = [];
        Utils.httpGetAssessmentInformation(
            this.http
            , this.baseUrl
            , waveId
            , this
            , function (tthis: AssessmentComponent, result: Assessment) {
                setTimeout(() => {
                    tthis.assessmentInfo = result
                    let indexCounter = 0;

                    const tempArray: any = []
                    tthis.assessmentInfo?.Functions.forEach((za: AssessmentFunction) => {
                        za.LineOfBusinesses.forEach((y: AssessmentLineOfBusiness) => {
                            y.ExcellenceLevers.forEach((z: AssessmentExcellenceLever) => {
                                tempArray.push({
                                    ...z,
                                    Function: za.Function,
                                    LineOfBusiness: y.LineOfBusiness,
                                    Index: indexCounter++,
                                    VisibleImprovementLevers: z.Action && z.Action?.trim() !== ''
                                })
                            })
                        })
                    })
                    tthis.childData = tempArray
                    tthis.label = `Start Date [${tthis.datePipe.transform(tthis.assessmentInfo?.StartDate, 'yyyy-MM-dd')}] End Date [${tthis.datePipe.transform(tthis.assessmentInfo?.EndDate, 'yyyy-MM-dd')}]`
                }, 50)
            }
            , function (tthis: AssessmentComponent, error: string) {
                tthis.toastrService.error('Cannot load assessment information for selected wave - Field Branch. Please, contact with administrator');
            }
        )
    }

    getExcellenceStages() {
        Utils.httpGetExcellenceStages(
            this.http
            , this.baseUrl
            , this
            , function (tthis: AssessmentComponent, result: ExcellenceStage[]) {
                setTimeout(() => {
                    tthis.stages = result
                }, 50)
            }
            , function (tthis: AssessmentComponent, error: string) {
                tthis.assessmentInfo = undefined
                tthis.childData = [];
            }
        );
    }

    getPriorities() {
        Utils.httpGetPriorities(
            this.http
            , this.baseUrl
            , this
            , function (tthis: AssessmentComponent, result: Priority[]) {
                setTimeout(() => {
                    tthis.priorities = result
                }, 50)
            }
            , function (tthis: AssessmentComponent, error: string) {
                tthis.assessmentInfo = undefined
                tthis.childData = [];
            }
        );
    }
    getBranchContacts() {
        Utils.httpGetFieldBranchContacts(
            this.http,
            this.baseUrl,
            this,
            function (tthis: AssessmentComponent, list: any) {
                tthis.contacts = list
            },
            null

        )
    }

    onClickSave(type: string) {
        const data: any[] = [];

        switch (type) {
            case 'assessment':
                const assessmentData = (this.assessmentViewList.dataSource.data as AssessmentExcellenceLever[])
                assessmentData.forEach(x => {
                    if (this.assessmentViewList.updatedItems.includes(x.WaveExcellenceLeverConfigId)) {
                        x.Waveid = this.waveId;
                        x.ManagementId = this.defaults.managementid;
                        data.push(x)
                    }
                })
                Utils.httpPostAssessmentInformation(
                    this.http,
                    this.baseUrl,
                    this.waveId,
                    data,
                    this,
                    function (tthis: AssessmentComponent, result: any) {
                        if (result) {
                            tthis.toastrService.success('Assessment information was saved successfully!');
                            tthis.loadInformation(tthis.waveId)
                        }
                        else
                            tthis.toastrService.error('Assessment information could not be saved. Please, contact with administrator');
                    },
                    null
                )
                break;
            case 'priorities':
                const prioritiesData = (this.prioritiesViewList.dataSource.data as AssessmentExcellenceLever[]);
                prioritiesData.forEach(x => {
                    if (this.prioritiesViewList.updatedItems.includes(x.WaveExcellenceLeverConfigId)) {
                        x.Waveid = this.waveId;
                        x.ManagementId = this.defaults.managementid;
                        data.push(x)
                    }
                })
                Utils.httpPostAssessmentInformation(
                    this.http,
                    this.baseUrl,
                    this.waveId,
                    data,
                    this,
                    function (tthis: AssessmentComponent, result: any) {
                        if (result) {
                            tthis.toastrService.success('Action priorization information was saved successfully!');
                            tthis.loadInformation(tthis.waveId)
                        }
                        else
                            tthis.toastrService.error('Action priorization information could not be saved. Please, contact with administrator');
                    },
                    null
                )
                break;
        }
    }

    onClickEdit() {
        if (this.isEditModeEnabled === true) {
            this.isEditModeEnabled = false
            if (this.assessmentViewList.updatedItems.length > 0 || this.prioritiesViewList.updatedItems.length > 0) {
                this.assessmentViewList.clearSelectedFactorValues();
                this.loadInformation(this.waveId)
            }
        }
        else {
            this.isEditModeEnabled = true
        }
    }

    getShowImprovementLeverTab() {
        return this.childData.some(item => item.VisibleImprovementLevers === true);
    }

    onClickExcel() {
        this.isExcelModeEnabled = true;
        this.isEditModeEnabled = false;
    }

    onClickExitExcel() {
        this.isExcelModeEnabled = false;
    }

    onClickDownload() {
        Utils.httpDownloadAssessmentConf(
            this.http,
            this.baseUrl,
            `AssessmentConf`,
            this.waveId,
            this.defaults.managementid
        );
    }

    onClickUpload() {
        this.router.navigate(["file/" + this.waveId +"-"+ this.defaults.managementid + "-" + Utils.getFileUploadAssessmentConf()]);
    }

}
