import { Component, ElementRef, Inject, NgModule, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utils } from '../../../../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { MatSort, Sort } from '@angular/material/sort';


@Component({
  selector: 'userhistorydialogue',
  templateUrl: './userhistorydialogue.component.html',
    styleUrls: ['./userhistorydialogue.component.less',
        '../../../../../style/changelog-dialog/changelog-dialog.less']
})
export class UserHistoryDialogue implements OnInit {

    public dataSource = new MatTableDataSource<UserChanges>();
    searchString: string = '';
    originalData: UserChanges[] = [];
    @ViewChild(MatSort) matSort = new MatSort();
    @ViewChild('tableBody') tableBody!: ElementRef;
    stageLabel: string = "";
    targetUser: string = "";
    constructor(
        private http: HttpClient,
        private dialogRef: MatDialogRef<UserHistoryDialogue>,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public datePipe: DatePipe
    )
    {
    }

    columnsToDisplay = ["date", "upn", "details"];
    
    ngOnInit(): void {
        this.searchString = '';
        this.originalData = [];

        Utils.httpGetUserHistory(
            this.http,
            this.baseUrl,
            this.data.userId,
            this,
            function (tthis: UserHistoryDialogue, result: UserHistory) {
                tthis.tableBody.nativeElement.style.visibility = "visible";
                tthis.dataSource.data = result.changesList;
                tthis.originalData = result.changesList;
                tthis.stageLabel = `Stage: ${result.stage}`; // all come with same stage filtered
                tthis.targetUser = `(${result.targetUpn})`; // all come for the same user
            }
        )
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.matSort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'date':
                    return new Date(item.utcDate);
                default:
                    return item[property];
            }
        };
    }

    close() {
        this.dialogRef.close();
    }


    async updateFilteredHistory() {
        this.dataSource.data = this.filterAndShowResults(this.searchString);
    }

    filterAndShowResults(searchString: string) {
        // If the search string is empty, show all items
        if (searchString === "") {
            return this.originalData;
        }
        return this.originalData.filter(item => searchString === "" ? true : item.upn.toLowerCase().includes(searchString.toLowerCase())
            || item.details.toLowerCase().includes(searchString.toLowerCase()));     
    }
}
