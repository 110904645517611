<div style="display:flow-root">
    <div style="float:left">
        <h5>Software Release to Field</h5>
    </div>
</div>
<div class="flex-center">
    <div class="row softwarereleasetofield">
        <div class="edituser col-md-3 col-sm-12">
            <software-release-levels (onLevelSelectedTrigger)="onNewLevelSelected($event)">
            </software-release-levels>
        </div>
        <div class="softwarereleases col-md-9 col-sm-12">
            <software-release-list [familyCodes]="familyCodes"
                [softwareReleases]="softwareReleases"></software-release-list>
        </div>
    </div>
</div>
