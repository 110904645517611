<div class="container-tab-lists">
    <div class="inner-tcontainer-tab">
        <div style="position:relative">
            <div class="deployment-info">
                <h2 class="flex-aligned-div" (click)="openDeploymentInformationPopup()">
                    Deployment information
                    <mat-icon data-html="true" class="info-icon">info</mat-icon>
                </h2>
            </div>
            <div class="tab-body">
                <div class="tab-content">
                    <mat-tab-group #tabGroup
                                   (selectedTabChange)="defaults.updateComponentTab($event.tab.textLabel);">
                        <mat-tab label="Software Releases" *ngIf="defaults.isMFGAdminPDCSUser">
                            <controllersoftwarerelease #softwareReleasesComponent (controllerSoftwareEvent)="loadControllerSoftware($event)"
                                                       [availableFamilyCodes]="availableFamilyCodes"
                                                       [availableProductUsages]="availableProductUsages"></controllersoftwarerelease>
                        </mat-tab>
                        <mat-tab label="Product Usage" *ngIf="defaults.isMFGAdminPDCSUser">
                            <productusage #productUsageComponent (productUsageEvent)="loadProductUsage($event)"></productusage>
                        </mat-tab>
                        <mat-tab label="Peripheral Software" *ngIf="defaults.isMFGAdminPDCSUser">
                            <peripheralsoftware #periphearalSoftwareComponent [availableFamilyCodes]="availableFamilyCodes"></peripheralsoftware>
                        </mat-tab>
                        <mat-tab label="Manufacturing Center Product" *ngIf="defaults.isMFGAdminPDCSUser || defaults.isMFGAdminCenterUser">
                            <manufacturingcenterproduct #manufacturingCenterProductComponent (centerProductEvent)="loadCenterProduct($event)"
                                                        [availableProductUsages]="availableProductUsages"
                                                        [availableCenters]="availableCenters"></manufacturingcenterproduct>
                        </mat-tab>
                        <mat-tab label="Controller Software For Config Generation" *ngIf="defaults.isMFGAdminPDCSUser || defaults.isMFGAdminCenterUser">
                            <sfwversionconfiggeneration #sfwVersionConfigGenerationComponent [availableCenterProducts]="availableCenterProducts"
                                                        [availableControllerSoftwareReleases]="availableControllerSoftwareReleases"
                                                        [availableCenters]="availableCenters"></sfwversionconfiggeneration>
                        </mat-tab>
                        <mat-tab label="Software Release to Field" *ngIf="defaults.isEMTFactorySupportEngineer">
                            <softwarereleasetofield #sfwSoftwareReleaseToField
                                                    *ngIf="isSoftwareReleaseToFieldSelected()"
                                                    [availableFamilyCodes]="availableFamilyCodes"></softwarereleasetofield>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>
