import { Component, Inject, OnInit } from '@angular/core';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { HttpClient } from "@angular/common/http";
import { Utils } from '../../../../utils/utils';
import { DialogService } from '../../../../services/dialog.service';
import { Router } from '@angular/router';

@Component({
    selector: 'niops',
    templateUrl: './niops.component.html',
    styleUrls: ['./niops.component.less', '../../../../style/new-generic-styles.component.less']
})
export class NiOpsComponent implements OnInit {

    isExcelModeEnabled = false;

    title: string;

    subscriptionManagementId: any;
    subscriptionFiscalYear: any;
    subscriptionPeriod: any;
    subscriptions: any[] = [];

    constructor(
        public defaults: DefaultValuesService
        , private http: HttpClient
        , private dialog: DialogService
        , @Inject('BASE_URL') private baseUrl: string
        , private router: Router
    ) {
        this.title = ` EOX NI Ops (${this.defaults.managementName} - ${this.defaults.fiscalYear - 1}/${this.defaults.fiscalYear} - Period ${this.defaults.period})`;
    }

    ngOnInit(): void {
        this.subscriptionManagementId = this.defaults.managementIdMessageChanges$.subscribe(() => { this.loadInformation() });
        this.subscriptionFiscalYear = this.defaults.fiscalYearMessageChanges$.subscribe(() => { this.loadInformation() });
        this.subscriptionPeriod = this.defaults.periodMessageChanges$.subscribe(() => { this.loadInformation() });
    }

    ngOnDestroy(): void {
        this.subscriptionManagementId.unsubscribe();
        this.subscriptionFiscalYear.unsubscribe();
        this.subscriptionPeriod.unsubscribe();
        this.subscriptions.forEach(x => x.unsubscribe())
    }

    onClickExcelButton(): void {
        this.isExcelModeEnabled = !this.isExcelModeEnabled;
    }

    downloadExcelInformation(): void {
        let date = new Date();
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        Utils.httpDownloadNiOpsInformation(
            this.http
            , this.baseUrl
            , `${year}${month}${day}_${this.defaults.managementid}_${this.defaults.fiscalYear - 1}/${this.defaults.fiscalYear}_P${this.defaults.period}_EOXNiOps`
            , this
            , function (tthis: NiOpsComponent, error: string) {
                tthis.dialog.showErrorDialog(error);
            });
    }

    goUploadFileView(): void {
        this.router.navigate(['/file/' + Utils.getFileUploadNiOps()]);
    }

    onClickCancelButton(): void {
        this.isExcelModeEnabled = !this.isExcelModeEnabled;
    }

    onClickUploadUserHistory(): void {
        let date = new Date();
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        Utils.httpGetNiOpsUserHistory(
            this.http
            , this.baseUrl
            , `${year}${month}${day}_${this.defaults.managementid}_${this.defaults.fiscalYear - 1}/${this.defaults.fiscalYear}_P${this.defaults.period}_EOXNiOpsHistoric`
            , this
            , function (tthis: NiOpsComponent, error: string) {
                tthis.dialog.showErrorDialog(error);
            });

    }

    private loadInformation(): void {
        this.subscriptions.forEach(x => x.unsubscribe())
        this.title = ` EOX NI Ops (${this.defaults.managementName} - ${this.defaults.fiscalYear - 1}/${this.defaults.fiscalYear} - Period ${this.defaults.period})`;
    }
}