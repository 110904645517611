import { Component, EventEmitter, Input, OnInit, Output, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { EquipmentManagenementService } from 'src/app/services/equipmentmanagement.service';
import { SaveService } from 'src/app/services/save.service';
import { DefaultValuesService } from '../../../../../services/defaultvalues.service';
import { forkJoin, of, takeUntil } from 'rxjs';
import { DestroyService } from 'src/app/services/destroyservice';
import { SoftwareReleaseItem } from '../../types/software-release';
import { EmtAllowedSoftwareRelease, EmtAllowedSoftwareReleaseLevelDto, EmtAllowedSoftwareReleaseType, EmtAllowedSoftwareReleaseValue } from 'src/app/interfaces/emt/emt-allowed-softwarer-release';

@Component({
  selector: 'software-release-list',
  templateUrl: './software-release-list.component.html',
  styleUrls: ['./software-release-list.component.less', '../../../../../style/new-generic-styles.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService]
})
export class SoftwareReleaseListComponent implements OnInit {
  public readonly ENVIRONMENT = "environment";
  public readonly TENANT = "tenant";
  public readonly COLUMNS = {
    [this.ENVIRONMENT]: ['version', 'type', 'familyCode', 'environment', 'actions'],
    [this.TENANT]: ['version', 'type', 'familyCode', 'tenant', 'environment', 'actions'],
  };
  public readonly environmentValues = [
    { text: 'Allowed', value: EmtAllowedSoftwareReleaseValue.Allowed },
    { text: 'Not Allowed', value: EmtAllowedSoftwareReleaseValue.NotAllowed },
  ];
  public readonly tenantValues = [
    { text: 'Inherit from environment', value: EmtAllowedSoftwareReleaseValue.NotSet },
    ...this.environmentValues
  ];

  public form: FormGroup;
  public columnsToDisplay = this.COLUMNS[this.ENVIRONMENT];
  public editableColumn = this.ENVIRONMENT;

  @Input()
  public softwareReleases: SoftwareReleaseItem[] = []

  @Input()
  public familyCodes: Map<number, string> = new Map();

  public selectedLevel?: EmtAllowedSoftwareReleaseLevelDto;

  @Output()
  public onRefresh = new EventEmitter<{}>();

  @ViewChild(MatTable, { static: true })
  public table!: MatTable<any>;
  public dataSource = new MatTableDataSource<any>();

  constructor(
    private readonly emtService: EquipmentManagenementService,
    private readonly toastrService: ToastrService,
    private readonly saveService: SaveService,
    private readonly formBuilder: FormBuilder,
    public readonly defaults: DefaultValuesService,
    @Self() private readonly destroy$: DestroyService
  ) {
    this.form = this.formBuilder.group({
      rows: this.formBuilder.array([])
    });
  }

  public ngOnInit() {
    this.defaults.stageMessageChanges$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.selectedLevel = undefined;
        this.clearForm();
      });

    this.clearForm();
    this.updateHeaders();

    if (this.selectedLevel) {
      this.loadSoftwareReleases();
    }
  }

  public changeSelectedLevel(newLevel: EmtAllowedSoftwareReleaseLevelDto) {
    this.selectedLevel = newLevel;
    this.clearForm();
    this.updateHeaders();
    this.loadSoftwareReleases();
  }

  public onRowEditInit(index: number) {
    const rows = this.form.get('rows') as FormArray;
    const row = rows.at(index);
    row.get('onEdit')?.patchValue(true);
  }

  public onRowEditCancel(index: number) {
    this.updateFormValue(index, 'onEdit', false);
    this.resetFormValue(index, this.editableColumn === this.ENVIRONMENT ? 'environmentValue' : 'tenantValue');
  }

  public onShowSavebutton() {
    this.saveService.showSaveButton();
  }

  public onSave() {
    const modifiedRows = this.form.value.rows.filter(x => x.onEdit);
    if (!modifiedRows.length) {
      return;
    }

    const request = {
      ...this.selectedLevel!,
      softwaresReleases: modifiedRows.map(x => ({
        id: x.id,
        type: x.type,
        value: this.editableColumn === this.ENVIRONMENT
          ? x.environmentValue
          : x.tenantValue,
      })),
    };

    this.emtService
      .updateAllowedSoftwareReleases(request)
      .subscribe({
        next: () => {
          modifiedRows.forEach(row => {
            row.onEdit = false;
            row.unmodified.environmentValue = row.environmentValue;
            row.unmodified.tenantValue = row.tenantValue;
          });
          this.toastrService.success('Software releases saved.');
        },
        error: (error) => {
          this.toastrService.error(error.error);
        },
      });
  }

  public hasChanges() {
    return this.form.value.rows.some(x => x.onEdit);
  }

  public getFormValue(i: number) {
    return this.form?.get('rows')?.value[i];
  }

  public formatEnvironmentValue(value: EmtAllowedSoftwareReleaseValue) {
    switch (value) {
      case EmtAllowedSoftwareReleaseValue.NotSet:
      case EmtAllowedSoftwareReleaseValue.NotAllowed:
        return 'Not allowed';
      case EmtAllowedSoftwareReleaseValue.Allowed:
        return 'Allowed';
    }
  }

  public formatTenantValue(tenantValue: EmtAllowedSoftwareReleaseValue, environmentValue: EmtAllowedSoftwareReleaseValue) {
    switch (tenantValue) {
      case EmtAllowedSoftwareReleaseValue.NotSet:
        return this.formatEnvironmentValue(environmentValue) + ' (inherited)';
      case EmtAllowedSoftwareReleaseValue.Allowed:
        return 'Allowed';
      case EmtAllowedSoftwareReleaseValue.NotAllowed:
        return 'Not allowed';
    }
  }

  public formatType(type: EmtAllowedSoftwareReleaseType) {
    return type === EmtAllowedSoftwareReleaseType.Controller ? 'Controller' : 'Peripheral';
  }

  private clearForm() {
    this.form = this.formBuilder.group({
      rows: this.formBuilder.array([]),
    });
    this.form.reset();
    this.dataSource.data = [];
  }

  private updateHeaders() {
    if (this.selectedLevel && this.selectedLevel.tenantId) {
      this.columnsToDisplay = this.COLUMNS[this.TENANT];
      this.editableColumn = this.TENANT;
    } else {
      this.columnsToDisplay = this.COLUMNS[this.ENVIRONMENT];
      this.editableColumn = this.ENVIRONMENT;
    }
  }

  private loadSoftwareReleases() {
    const isTenant = !!this.selectedLevel?.tenantId;
    const getEnvironmentReleases$ = this.emtService.getAllowedSoftwareReleases({ ...this.selectedLevel!, tenantId: undefined })
    const getTenantReleases$ = isTenant
      ? this.emtService.getAllowedSoftwareReleases(this.selectedLevel!)
      : of(new Array<EmtAllowedSoftwareRelease>());

    forkJoin([getEnvironmentReleases$, getTenantReleases$])
      .subscribe({
        next: ([environmentReleases, tenantReleases]) => {
          this.fillForm(environmentReleases, tenantReleases);
        },
        error: () => {
          this.toastrService.error("Unable to load software releases!");
        }
      });
  }

  private fillForm(environmentReleases: EmtAllowedSoftwareRelease[], tenantReleases: EmtAllowedSoftwareRelease[]) {
    const releaseKey = (r: { type: EmtAllowedSoftwareReleaseType, id: number }) => `${r.type}-${r.id}`;
    const environmentReleasesMap = new Map(environmentReleases.map(r => [releaseKey(r), r]));
    const tenantReleasesMap = new Map(tenantReleases.map(r => [releaseKey(r), r]));

    this.form = this.formBuilder.group({
      rows: this.formBuilder.array(
        this.softwareReleases.map(release => {
          const key = releaseKey(release);
          const environmentValue = environmentReleasesMap.get(key)?.value ?? EmtAllowedSoftwareReleaseValue.NotAllowed;
          const tenantValue = tenantReleasesMap.get(key)?.value ?? EmtAllowedSoftwareReleaseValue.NotSet;

          return this.formBuilder.group({
            id: new FormControl(release.id),
            type: new FormControl(release.type),
            version: new FormControl(release.version),
            familyCode: new FormControl(this.familyCodes.get(release.familyCodeId)),
            environmentValue: new FormControl(environmentValue),
            tenantValue: new FormControl(tenantValue),
            onEdit: new FormControl(false),
            unmodified: new FormControl({ environmentValue, tenantValue })
          });
        }))
    });

    this.dataSource = new MatTableDataSource((this.form.get('rows') as FormArray).controls);
  }

  private updateFormValue(index: number, fieldName: string, newValue: any) {
    const rows = this.form.get('rows') as FormArray;
    const row = rows.at(index);
    row.get(fieldName)?.setValue(newValue);
  }

  private resetFormValue(index: number, fieldName: string) {
    const rows = this.form.get('rows') as FormArray;
    const row = rows.at(index);
    const value = row.value.unmodified[fieldName];
    row.get(fieldName)?.setValue(value);
  }
}
