import { Component, Inject, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { DialogService } from "../../services/dialog.service";
import { WaitingService } from '../../services/waiting.service';
import { Utils } from "../../utils/utils";
import { CustomDialogService } from "../../services/customdialog.service";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "import-form",
    templateUrl: './import-form.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class ImportFormComponent implements OnInit {
    title: string | undefined;
    message: string | undefined;
    form: FormGroup | undefined;

    file: File | null | undefined;
    description = ''

    resource = -1;
    substring=''
    uri = ''
    isHistorical: boolean | undefined

    constructor(
        private location: Location
        , private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private fb: FormBuilder
        , private cd: ChangeDetectorRef
        , @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
        , private waiting: WaitingService
        , private dialog: DialogService
        , private customDialog: CustomDialogService
        , private toastrService: ToastrService

        //, public signalRService: SignalRService
    ) {
    }

    ngOnInit() {
        this.dialog.tthis = this;
        this.resource = +this.activatedRoute.snapshot.params["resource"];
        this.substring = this.activatedRoute.snapshot.params["resource"];

        this.title = "Upload Excel file with ";
        this.message = "This upload create a new "; // + (this.resource == Utils.getFileUploadErrorCodeValue() ?  : "Operation Modes") + " for " + this.defaults.controllertype.Description + ", per line of the Excel file."
        if (this.resource === Utils.getFileUploadErrorCodeValue() && this.defaults.controllertype.BK !== 'CT1001') {
            this.title += "Error Codes";
            this.message += "Error Code and Possible Resolutions";
            this.uri = 'api/ImportExportModel/uploaderrorcodes';
            this.description = 'for ' + this.defaults.controllertype.Description
        }
        else if (this.resource === Utils.getFileUploadOpModeValue()) {
            this.title += "Operation Modes";
            this.message += "Operation Modes";
            this.uri = 'api/ImportExportModel/uploadoperationmodes';
            this.description = 'for ' + this.defaults.controllertype.Description
        }
        else if (this.resource === Utils.getFileUploadContracts()) {
            this.title += "Contracts";
            this.message += "Contracts";
            this.uri = 'api/ImportExportModel/uploadcontracts';
        }
        else if (this.resource === Utils.getFileUploadCustomers()) {
            this.title += "Customers";
            this.message += "Customers";
            this.uri = 'api/ImportExportModel/uploadcustomers';
        }
        else if (this.resource === Utils.getFileUploadElevators()) {
            this.title += "Elevators";
            this.message += "Elevators";
            this.uri = 'api/ImportExportModel/uploadelevators';
        }
        else if (this.resource === Utils.getFileUploadEscalators()) {
            this.title += "Escalators";
            this.message += "Escalators";
            this.uri = 'api/ImportExportModel/uploadescalators';
        }
        else if (this.resource === Utils.getFileUploadBranchHierarchy()) {
            this.title += "Branch Hierarchy";
            this.message += "Branch Hierarchy";
            this.uri = 'api/ImportExportModel/uploadbranchhierarchy';
        }
        else if (this.resource === Utils.getFileUploadServiceTickets()) {
            this.title += "Service Tickets";
            this.message += "Service Tickets";
            this.uri = 'api/ImportExportModel/uploadservicetickets/{{isHistorical}}';
        }
        else if (this.resource === Utils.getFileUploadChairlifts()) {
            this.title += "Chairlifts";
            this.message += "Chairlifts";
            this.uri = 'api/ImportExportModel/uploadchairlifts';
        }
        else if (this.resource === Utils.getFileUploadOtherAssets()) {
            this.title += "Other Assets";
            this.message += "Other Assets";
            this.uri = 'api/ImportExportModel/uploadotherassets';
        }
        else if (this.resource === Utils.getFileUploadContractFacilitiesManager()) {
            this.title += "Contract Facilities Managers";
            this.message += "Contract Facilities Managers";
            this.uri = 'api/ImportExportModel/uploadcontractfacilitymanager';
        }
        else if (this.resource === Utils.getFileUploadCustomerFacilitiesManager()) {
            this.title += "Customer Facilities Managers";
            this.message += "Customer Facilities Managers";
            this.uri = 'api/ImportExportModel/uploadcustomerfacilitymanager';
        }
        else if (this.resource === Utils.getFileKpi()) {
            this.title += "Kpis";
            this.message += "Kpis";
            this.uri = 'api/ImportExportModel/uploadkpis';
        }
        else if (this.resource === Utils.getFileUploadErrorCodeValue() && this.defaults.controllertype.BK === 'CT1001') {
            this.title += "Error Codes Digital Services";
            this.message += "Error Codes Digital Services";
            this.uri = 'api/ImportExportModel/uploadDigitalServices';
        }
        else if (this.resource === Utils.getFileUploadConfigIt()) {
            this.title = "Upload XML file with Config IT elevators";
            this.message += "request for creating new elevators.";
            this.uri = 'api/ImportExportModel/uploadbomfile';
        }
        else if (this.resource === Utils.getFileUploadConfigIt()) {
            this.title = "Upload XML file with Config IT elevators";
            this.message += "request for creating new elevators.";
            this.uri = 'api/ImportExportModel/uploadbomfile';
        }
        else if (this.resource === Utils.getFileUploadActualReportingInformation()) {
            this.title = "Upload Actual Reporting Information file";
            this.message += "request for updating actual reporting information";
            this.uri = 'api/ImportExportModel/uploadactualreporting/false';
        }
        else if (this.resource === Utils.getFileUploadOplYearlyInformation()) {
            this.title = "Upload OPL Yearly Information file";
            this.message += "request for uploading opl yearly information";
            this.uri = 'api/ImportExportModel/uploadoplyearly/false';
        }
        else if (this.resource === Utils.getFileUploadOplMonthlyInformation()) {
            this.title = "Upload OPL Monthly Information file";
            this.message += "request for uploading opl monthly information";
            this.uri = 'api/ImportExportModel/uploadoplmonthly/false';
        }
        else if (this.resource === Utils.getFileUploadActualBranchReportingInformation()) {
            this.title = "Upload Actual Branch Reporting Information file";
            this.message += "request for updating actual branch reporting information";
            this.uri = 'api/ImportExportModel/uploadactualreporting/true';
        }
        else if (this.resource === Utils.getFileUploadOplYearlyBranchReportingInformation()) {
            this.title = "Upload OPL Yearly Branch Reporting Information file";
            this.message += "request for uploading opl yearly branch reporting information";
            this.uri = 'api/ImportExportModel/uploadoplyearly/true';
        }
        else if (this.resource === Utils.getFileUploadOplMonthlyBranchReportingInformation()) {
            this.title = "Upload OPL Monthly Branch Reporting Information file";
            this.message += "request for uploading opl monthly branch reporting information";
            this.uri = 'api/ImportExportModel/uploadoplmonthly/true';
        }
        else if (this.resource === Utils.getFileUploadMarketSheet()) {
            this.title = "Upload Market Sheet file";
            this.message += "request for uploading market sheet information";
            this.uri = 'api/ImportExportModel/upload/marketquery/MARKET';
        }
        else if (this.resource === Utils.getFileUploadSegmentSheet()) {
            this.title = "Upload Segment Sheet file";
            this.message += "request for uploading segment sheet information";
            this.uri = 'api/ImportExportModel/upload/marketquery/SEGMENT';
        }
        else if (this.resource === Utils.getFileUploadExcellenceLever()) {
            this.title = "Upload Excellence Lever file";
            this.message += "request for Excellence Lever information";
            this.uri = 'api/ImportExportModel/upload/excellenceFactor/lever';
        }
        else if (this.resource === Utils.getFileUploadExcellenceLeverKPI()) {
            this.title = "Upload Excellence Lever KPI file";
            this.message += "request for Excellence Lever KPI information";
            this.uri = 'api/ImportExportModel/upload/excellenceFactor/kpi';
        }
        else if (this.resource === Utils.getFileUploadExcellenceFactor()) {
            this.title = "Upload ExcellenceFactor file";
            this.message += "request for ExcellenceFactor information";
            this.uri = 'api/ImportExportModel/upload/excellenceFactor/factor';
        }
        else if (this.substring.includes("-" + Utils.getFileUploadAssessmentConf())) {
            var values = this.substring.split("-")
            this.title = "Upload Assessment Config file";
            this.message += "request for AssessmentConfig information";
            this.uri = `api/ImportExportModel/upload/assessmentconf/${values[0]}/${values[1]}` ;
        }
        else if (this.resource === Utils.getFileUploadNiOps()) {
            this.title = "Upload EOX Ni Ops reporting file";
            this.message += "request for updating EOX Ni Ops information";
            this.uri = 'api/ImportExportModel/upload/niopsreporting';
        }

        if (this.resource !== Utils.getFileUploadConfigIt())
            this.message += ", per line of the Excel file.";
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            File: ['', Validators.required]
        });
    }

    onFileChange(event: any) {
        event = (event?.target as HTMLInputElement)?.files;
        if (event && event.length) {
            this.file = event[0];
        }
    }

    onBack() {
        this.location.back();
    }

    onSubmit() {
        if (this.resource === Utils.getFileUploadServiceTickets()) {
            this.customDialog.openConfirm({
                title: "Service Ticket Upload Type",
                message: `Do you want to execute a massive upload? Massive upload will not send service tickets to CosmosDB and therefore, we will not generate notifications to the customers.`,
                caller: this
            });
            this.customDialog.confirmed().subscribe(res => {
                if (res === true)
                    return;
                if (res.confirmed) {
                    this.isHistorical = true
                }
                else {
                    this.isHistorical = false
                }
                this.sendPetition();
            })
        }

        else if (this.resource === Utils.getFileUploadErrorCodeValue() && this.defaults.controllertype.BK === 'CT1001') {
            this.customDialog.openConfirm({
                title: "Digital Services Error Codes Upload Type",
                message: `Do you want to provide a DES file with error codes and which devices are related or a traditional file with error codes with their resolutions? Please click on 'Yes' for DES file or 'No' for traditional file`,
                caller: this
            });
            this.customDialog.confirmed().subscribe(res => {
                if (res === true)
                    return;
                if (res.confirmed)
                    this.uri = 'api/ImportExportModel/uploadDigitalServices';
                else
                    this.uri = 'api/ImportExportModel/uploaderrorcodes';
                this.sendPetition();
            })
        }
        else {
            this.sendPetition();
        }
    }

    sendPetition() {
        if (this.form && this.form.valid && this.file) {
            let formData: FormData = new FormData();
            formData.append(this.file.name, this.file);

            let headers = new HttpHeaders();
            headers.append('Accept', 'application/json');
            headers.append('Content-Type', 'multipart/form-data');

            // this.http is the injected HttpClient
            const uploadData = new FormData();
            uploadData.append('myFile', this.file, this.file.name);
            this.http
                .post(this.baseUrl + this.uri.replace('{{env}}', String(this.defaults.stage)).replace('{{isHistorical}}', String(this.isHistorical))
                    , formData //, this.file//, uploadData
                    , { headers: headers }
                )
                .subscribe(
                    (res: any) => {
                        if (res === true && (this.resource === Utils.getFileUploadActualReportingInformation() || this.resource === Utils.getFileUploadActualBranchReportingInformation()
                            || this.resource === Utils.getFileUploadOplMonthlyInformation()) || this.resource === Utils.getFileUploadOplMonthlyBranchReportingInformation()) {
                            this.toastrService.success('Kpi values were updated successfully!');
                        }
                        if (res === true && (this.resource === Utils.getFileUploadMarketSheet() || this.resource === Utils.getFileUploadSegmentSheet())) {
                            this.toastrService.success('Values were updated successfully!');
                        }
                        if (res === true && (this.resource === Utils.getFileUploadNiOps())) {
                            this.toastrService.success('EOX NI Ops Information were updated successfully!');
                        }
                        else if (this.resource === Utils.getFileUploadOplYearlyInformation() || this.resource === Utils.getFileUploadOplYearlyBranchReportingInformation()) {
                            if (res.length > 0) {
                                this.dialog.showSuccessDialog(res.map((object) => object).join('; '),
                                    'Following accumulative Accounts did not include a forecast for current fiscal year. Consequently, automatic monthly splitting may not be accurate',
                                    [])
                            }
                        }
                        else if (this.resource === Utils.getFileUploadContracts()) {
                            if (res === 1) {
                                this.dialog.showErrorDialog(
                                    {
                                        'error':
                                            { 'message': 'Please, be sure all mandatory fields (Contract Number, unitId, CustomerId, ContractType, Contract Locale, Contract Start Date and Contract EndDate) are populated' }
                                    })
                            }
                            else if (res === 2) {
                                this.dialog.showErrorDialog(
                                    {
                                        'error':
                                            { 'message': 'Please, check IsPrimaryContract and ContractLine fields. If item is primary contract, both ContractLine and ContractLineLocale must be empty. Otherwise, both fields must be populated.' }
                                    })
                            }
                        }
                        else if (res === false) {
                            let message = "File could not be processed due to some rows might have not been populated correctly (Excel Grey Columns are mandatory)."

                            if (this.resource === Utils.getFileUploadElevators() || this.resource === Utils.getFileUploadEscalators()
                                || this.resource === Utils.getFileUploadOtherAssets() || this.resource === Utils.getFileUploadChairlifts())
                                message += " Also check whether provided branch numbers are correct ones."
                            this.dialog.showErrorDialog({ 'error': { "message": message } });
                        }

                        this.waiting.toWait(false);
                        this.onBack();
                    }
                    , error => { this.waiting.toWait(false); this.dialog.showErrorDialog(error); }
                );
        }
    }
}