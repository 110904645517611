<div class="container-tab-lists">
    <mat-tab-group orientation="vertical" #tabGroup (selectedTabChange)="defaults.updateComponentTab($event.tab.textLabel)">
        <!--Messages-->
        <mat-tab label="Branch Config">
            <div class="inner-tcontainer-tab">
                <branchconfig></branchconfig>
                <branchcontact></branchcontact>
            </div>
        </mat-tab>

        <mat-tab label="Assessment Config">
            <div class="inner-tcontainer-tab">
                <div class="container-tab-lists new-table-style">
                    <h2>{{label}}</h2>
                    <div style="display:flow-root">
                        <div style="text-align:right; float:right">

                            <a class='kpi-button-menu' title="Excel" (click)="onClickExcel()" *ngIf="!isExcelModeEnabled && !isEditModeEnabled">
                                Excel
                            </a>

                            <a class='kpi-button-menu' title="Dowload" (click)="onClickDownload()" *ngIf="isExcelModeEnabled">
                                <span class='glyphicon glyphicon-download'></span> Download
                            </a>

                            <a class='kpi-button-menu' title="Upload" (click)="onClickUpload()" *ngIf="isExcelModeEnabled">
                                <span class='glyphicon glyphicon-upload'></span> Upload
                            </a>

                            <a class='kpi-button-menu' title="Excel" (click)="onClickExitExcel()" *ngIf="isExcelModeEnabled">
                                Exit Excel Mode
                            </a>




                            <a class='kpi-save-menu save-button' title="Save" *ngIf="isEditModeEnabled" (click)="onClickSave('assessment')">
                                <span class='glyphicon glyphicon-floppy-disk'></span> Save
                            </a>

                            <a class='kpi-button-menu' title="Edit values" (click)="onClickEdit()" *ngIf="defaults.isMersyAdminBranchExcellenceUser || defaults.isMersyReporterBranchExcellenceUser">
                                <span class='glyphicon glyphicon-pencil'></span> {{isEditModeEnabled ? 'Undo' : 'Edit'}}
                            </a>
                            <h5 [style.visibility]="isEditModeEnabled === true ? 'visible' : 'hidden'">
                                Edit Mode is enabled. Please click on Save button to save all changes
                            </h5>
                        </div>
                    </div>
                    <br />
                    <assessment-list [childData]="childData"
                                     [stages]="stages"
                                     [contacts]="contacts"
                                     [isEditModeEnabled]="isEditModeEnabled"></assessment-list>
                </div>

            </div>
        </mat-tab>

        <mat-tab label="Improvement Levers" *ngIf="getShowImprovementLeverTab()">
            <div class="inner-tcontainer-tab">
                <div class="container-tab-lists new-table-style">
                    <h2>{{label}}</h2>
                    <div style="display:flow-root">
                        <div style="text-align:right; float:right">

                            <a class='kpi-save-menu save-button' title="Save" *ngIf="isEditModeEnabled" (click)="onClickSave('priorities')">
                                <span class='glyphicon glyphicon-floppy-disk'></span> Save
                            </a>

                            <a class='kpi-button-menu' title="Edit values" (click)="onClickEdit()" *ngIf="defaults.isMersyAdminBranchExcellenceUser || defaults.isMersyReporterBranchExcellenceUser">
                                <span class='glyphicon glyphicon-pencil'></span> {{isEditModeEnabled ? 'Undo' : 'Edit'}}
                            </a>
                            <h5 [style.visibility]="isEditModeEnabled === true ? 'visible' : 'hidden'">
                                Edit Mode is enabled. Please click on Save button to save all changes
                            </h5>
                        </div>
                    </div>
                    <br />
                    <action-priorization-list [childData]="childData"
                                              [isEditModeEnabled]="isEditModeEnabled"
                                              [priorities]="priorities"></action-priorization-list>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>