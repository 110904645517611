import { Component, Output, Inject, EventEmitter, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

import { Utils } from '../../utils/utils';
import { DialogService } from '../../services/dialog.service';
import { SaveService } from '../../services/save.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { GroupFunctionComponent } from '../groupfunction/groupfunction.component';

interface MGMTBranchNode {
    Value: string;
    GroupName: string;
    subGroupsAllowed: boolean;
    Selected?: boolean;
    Disabled?: boolean;
    Indeterminate?: boolean;
    Parent?: MGMTBranchNode;
    Groups: MGMTBranchNode[];
    GroupFunction: string;
    BusinessLevel: string;
    IsNiOPSRelated: boolean;
}

@Component({
    selector: 'managementid',
    templateUrl: './managementid.component.html',
    styleUrls: ['./managementid.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class ManagementIdComponent implements AfterViewInit, OnDestroy {
    @Output() managementidChanged = new EventEmitter<string>();

    @ViewChild(MatAutocompleteTrigger) mgmtAutocomplete: MatAutocompleteTrigger = {} as MatAutocompleteTrigger;
    @ViewChild(GroupFunctionComponent) GroupFuncComp: GroupFunctionComponent = {} as GroupFunctionComponent;

    protected _onDestroy = new Subject<void>();

    groupfunction = this.GroupFuncComp.selectedgroupFunction;
    selGrpFuncFrmAll = "";
    selectorValue: string = 'All';
    selectedManagementId: string = '';
    allToggelButtonOption = [];
    value = '';
    lastSelectedNode: MGMTBranchNode = {} as MGMTBranchNode;

    userId = '';
    public TREE_DATA: MGMTBranchNode[] = [];
    public arrayFiltered: MGMTBranchNode[] = [];

    public selectedNodes: string[] = [];

    public treeControl: NestedTreeControl<MGMTBranchNode>;
    public dataSource = new MatTreeNestedDataSource<MGMTBranchNode>();

    public searchString = '';
    public showOnlySelected = false;
    Tree_Response_State: number = -2; // 0 -> empty, 1 -> correct and not empty, -1 -> error, -2 -> Calling API

    mode: ManagementMode = ManagementMode.BranchReporting;
    isCallSucceed: number = 0;
    placeHolder = "";
    previousPlaceHolder = "";

    modeEnum: typeof ManagementMode = ManagementMode;

    constructor(
        private toastrService: ToastrService,
        private route: ActivatedRoute,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        public defaults: DefaultValuesService,
        private dialog: DialogService,
        private saveService: SaveService,
        private router: Router
    ) {
        this.treeControl = new NestedTreeControl<MGMTBranchNode>((node) => node.Groups);
    }

    ngOnInit() { }

    ngAfterViewInit() {
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                this.updateModeBasedOnUrl(event.url);
                this.funcGetUserMgmtBranches();
            }
        });

        this.defaults.languageMessageChanges$.subscribe(() => {
            this.funcGetUserMgmtBranches();
        });

        this.GroupFuncComp.groupFunctionChanged.subscribe((groupFunction: string) => {
            this.groupfunction = groupFunction;
            this.selectFirstMgmtId(groupFunction, true);
        });

        this.funcGetUserMgmtBranches();
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    // Update mode based on the current URL
    private updateModeBasedOnUrl(url: string) {
        if (url === '/actualreporting' || url === '/oplkpimanagement' || url === '/file/15' || url === '/file/16' || url === '/file/17' || url === '/market-sheet' || url === '/segment-sheet' || url === '/file/21' || url === '/file/22') {
            this.mode = ManagementMode.Mersy;
        } else if (url === '/branch-excellence-admin' || url === '/assessment') {
            this.mode = ManagementMode.BranchExcellence;
        } else if (url === '/niops' || url === '/file/27') {
            this.mode = ManagementMode.NIOPSReporting;
        } else {
            this.mode = ManagementMode.BranchReporting;
        }
    }

    // Change the management ID
    private changeManagementId() {
        if (this.value !== '-1') {
            const url = this.baseUrl + Utils.getManagementIdAPI();
            this.defaults.managementid = this.value;
            this.http.post<string>(url, { 'value': this.defaults.managementid }).subscribe(() => {
                this.defaults.notifyManagementIdChange();
                this.placeHolder = this.selectedManagementId;
            }, error => Utils.writeLog(this.http, this.baseUrl, error));
        }
    }

    // Handle the "No" action
    private onNo() {
        this.saveService.fireCancelAll();
        this.changeManagementId();
    }

    // Handle the selection of a management ID
    private onSelect(managementid: any) {
        if (!managementid || !managementid.Value || !this.defaults.managementids) return;
        this.value = managementid.Value;
        if (this.saveService.isSaveButtonShown()) {
            this.dialog.showConfirmDialog("Do you want to save the changes? If you select No, all changes will be lost!", "Save", this);
        } else {
            this.onNo();
        }
    }

    // Handle the "Yes" action
    private onYes() {
        this.saveService.fireSave();
        this.changeManagementId();
    }

    // Check if a node has children
    public hasChild = (_: number, node: MGMTBranchNode) => !!node.Groups && node.Groups.length > 0;

    // Set the parent for a node
    private setParent(node: MGMTBranchNode, Parent: MGMTBranchNode | undefined) {
        if (node) node.Parent = Parent;
        if (node.Groups) {
            node.Groups.forEach((childNode) => {
                this.setParent(childNode, node);
            });
        }
    }

    // Check all parents of a node
    private checkAllParents(node: MGMTBranchNode) {
        if (node.Parent) {
            const descendants = this.treeControl.getDescendants(node.Parent);
            node.Parent.Indeterminate = descendants.some((child) => child.Selected);
            this.checkAllParents(node.Parent);
        }
    }

    // Get the parent string for a node
    private GetParentsString(node: MGMTBranchNode) {
        if (node.Parent) {
            this.treeControl.expand(node.Parent);
            this.selectedManagementId = node.Parent.GroupName + ' / ' + this.selectedManagementId;
            this.GetParentsString(node.Parent);
        }
    }

    // Toggle the selection of a node
    public async itemToggle(checked: boolean, node: MGMTBranchNode, isUserInteraction: boolean = true) {
        this.selectedManagementId = "";
        this.unselectAll();
        this.treeControl.collapseAll();
        node.Selected = checked;

        if (this.defaults.managementName != node.GroupName) {
            this.defaults.managementName = node.GroupName;
            this.value = node.Value;
        }
        this.selectedManagementId = node.GroupName;

        this.checkAllParents(node);
        this.GetParentsString(node);

        if (isUserInteraction) {
            this.searchString = "";
            this.defaults.groupFunction = node.GroupFunction;
            await this.GroupFuncComp.onNo();
            this.onSelect(node);
            this.closeAutocomplete();
        }
        this.selGrpFuncFrmAll = '';
    }

    // Methods for handling the select first mgmt id available
    public selectFirstMgmtId(groupFunctionSelected: string, isGroupFunctionChanged = false) {
        this.groupfunction = groupFunctionSelected;
        this.arrayFiltered = this.filterNodesByGroupFunction(this.groupfunction);

        let matchNode = this.findMatchNode();

        if (!matchNode && !isGroupFunctionChanged) {
            this.groupfunction = this.searchNode(this.defaults.managementid, this.dataSource.data)?.GroupFunction ?? groupFunctionSelected;
            this.arrayFiltered = this.filterNodesByGroupFunction(this.groupfunction);
            matchNode = this.findMatchNode();
        }

        if (this.arrayFiltered.length > 0) {
            if (matchNode) {
                this.handleMatchNode(matchNode);
            } else {
                this.handleNoMatchNode(isGroupFunctionChanged);
            }
        }
    }

    private findMatchNode(): MGMTBranchNode | undefined {
        return this.arrayFiltered.length > 0 ? this.arrayFiltered.find(node => node?.Value === this.defaults.managementid) : undefined;
    }

    private handleMatchNode(matchNode: MGMTBranchNode) {
        if (this.mode === ManagementMode.BranchExcellence && matchNode.BusinessLevel === 'MANAGEMENT') {
            const newNode = matchNode.Groups[0];
            this.itemToggle(true, newNode, true);
            this.defaults.managementName = matchNode.GroupName;
        } else {
            this.itemToggle(true, matchNode, false);
            this.defaults.managementName = matchNode.GroupName;
        }
    }

    private handleNoMatchNode(isGroupFunctionChanged: boolean) {
        this.unselectAll();

        if (this.mode === ManagementMode.Mersy) {
            const temp = this.defaults.managementids[0] as any;
            if (!isGroupFunctionChanged) {
                this.setParent(temp, undefined);
                const node = this.getParentByChild(temp, this.defaults.managementid);
                if (node && node.Parent) {
                    this.itemToggle(true, node.Parent, false);
                    this.onSelect(node.Parent);
                }
            }
        } else if (this.mode === ManagementMode.BranchReporting || this.mode === ManagementMode.NIOPSReporting) {
            this.itemToggle(true, this.arrayFiltered[0], true);
        }
    }

    // Unselect all nodes
    private unselectAll() {
        this.treeControl.dataNodes.forEach((branchNode) => {
            branchNode.Selected = false;
            branchNode.Indeterminate = false;
            if (branchNode.Groups) {
                const alldecendents = this.treeControl.getDescendants(branchNode);
                alldecendents.forEach((node) => (node.Selected = false, node.Indeterminate = false));
            }
        });
    }

    // Unselect a specific node
    private itemUnselect(node: MGMTBranchNode) {
        const descendants = this.treeControl.getDescendants(node);
        descendants.forEach(node => { node.Indeterminate = false; node.Selected = false });
    }

    // Disable a specific node
    private itemDisable(checked: boolean, node: MGMTBranchNode) {
        const descendants = this.treeControl.getDescendants(node);
        descendants.forEach(node => node.Disabled = checked);
    }

    // Hide leaf nodes based on certain conditions
    public hideLeafNode(node: MGMTBranchNode): boolean {
        return (this.searchString !== '' && new RegExp(this.searchString, 'i').test(node.GroupName) === false)
            || ((this.mode === ManagementMode.BranchReporting || this.mode === ManagementMode.BranchExcellence) && node.BusinessLevel !== 'FIELDBRANCH')
            || (this.mode === ManagementMode.Mersy && node.BusinessLevel !== 'MANAGEMENT')
            || (this.mode === ManagementMode.NIOPSReporting && node.IsNiOPSRelated === false);
    }

    // Hide leaf nodes based on group function selector
    public hideLeafNodeselector(selVal, node: MGMTBranchNode): boolean {
        return this.groupFuncExist(selVal, node) === false
            || ((this.mode === ManagementMode.BranchReporting || this.mode === ManagementMode.BranchExcellence) && node.BusinessLevel !== 'FIELDBRANCH')
            || ((this.mode === ManagementMode.Mersy) && node.BusinessLevel !== 'MANAGEMENT');
    }

    // Check if a group function exists for a node
    public groupFuncExist(selVal: string, node: MGMTBranchNode) {
        if (node.GroupFunction) {
            var GroupFunction = node.GroupFunction;
            if (GroupFunction.includes('/')) {
                const namesArray = GroupFunction.split('/').map(name => name.trim().toLowerCase());
                return namesArray.includes(selVal.toLowerCase()) || selVal.toLowerCase() === 'all';
            } else {
                return GroupFunction.trim().toLowerCase() === selVal.toLowerCase() || selVal.toLowerCase() === 'all';
            }
        }
        return false;
    }

    // Hide parent nodes based on certain conditions
    public hideParentNode(node: MGMTBranchNode): boolean {
        if (this.searchString !== '' && node.GroupName.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1) {
            return false;
        }
        const descendants = this.treeControl.getDescendants(node);
        if (descendants.some((descendantNode) =>
            descendantNode.GroupName.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1 &&
            ((((
                this.mode === ManagementMode.Mersy
                || (this.mode === ManagementMode.NIOPSReporting))
                || this.mode === ManagementMode.BranchExcellence) && descendantNode.BusinessLevel !== 'FIELDBRANCH')
                || this.mode === ManagementMode.BranchReporting)
        )) {
            return false;
        }
        return true;
    }

    // Hide parent nodes based on group function selector
    public hideParentNodeselector(selVal, node: MGMTBranchNode): boolean {
        if (!selVal || (node.GroupFunction && node.GroupFunction.toLowerCase().indexOf(selVal.toLowerCase()) !== -1)) {
            return false;
        }
        const descendants = this.treeControl.getDescendants(node);
        if (descendants.some((descendantNode) => descendantNode.GroupFunction && this.groupFuncExist(selVal, descendantNode))) {
            return false;
        }
        return true;
    }

    // Hide parent management nodes based on certain conditions
    public hideParentManagement(node: MGMTBranchNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        if (descendants.some((descendantNode) =>
            this.mode === ManagementMode.Mersy
            || this.mode === ManagementMode.NIOPSReporting && (descendantNode.IsNiOPSRelated === true || node.IsNiOPSRelated === true)
            || ((this.mode === ManagementMode.BranchReporting || this.mode === ManagementMode.BranchExcellence) && descendantNode.BusinessLevel === 'FIELDBRANCH'))) {
            return false;
        }
        return true;
    }

    // Fetch user management branches
    private funcGetUserMgmtBranches() {
        this.Tree_Response_State = 1;
        var temp: any;
        temp = this.defaults.managementids;
        this.TREE_DATA = temp;
        this.treeControl.dataNodes = this.TREE_DATA;
        this.dataSource.data = this.TREE_DATA;
        Object.keys(this.dataSource.data).forEach((key) => {
            this.setParent(this.dataSource.data[key], undefined);
        });
        /*this.groupfunction = this.defaults.groupFunction;*/
        this.selectFirstMgmtId(this.GroupFuncComp.selectedgroupFunction);
        this.placeHolder = this.selectedManagementId;
    }

    // Get the selected item
    private getSelectedItem() {
        return this.selectedManagementId;
    }

    // Expand all nodes in the tree control
    treeControlFunc() {
        this.searchString === "" ? "" : this.treeControl.expandAll();
    }

    // Close the autocomplete panel
    closeAutocomplete() {
        if (this.mgmtAutocomplete) {
            this.mgmtAutocomplete.closePanel();
        }
    }

    // Handle the autocomplete closed event
    onAutocompleteClosed() {
        //this.GroupFuncComp.resetGrpFunctionDefault();
        this.searchString = "";
        this.placeHolder = this.previousPlaceHolder;
        this.selectFirstMgmtId(this.GroupFuncComp.selectedgroupFunction);
    }

    // Handle the autocomplete opened event
    onAutocompleteOpened() {
        this.previousPlaceHolder = this.placeHolder;
        this.placeHolder = "Search";
    }

    // Get the parent node by child node
    getParentByChild(object: MGMTBranchNode, branchId: string): MGMTBranchNode | undefined {
        if (object.Value === branchId) {
            return object;
        }
        if (object?.Groups) {
            for (const item of object?.Groups) {
                const result = this.getParentByChild(item, branchId);
                if (result !== null && result?.Parent) {
                    return result;
                }
            }
        }
        return undefined;
    }

    // Show the expand icon for a node
    showExpandIcon(node: MGMTBranchNode) {
        return this.mode === ManagementMode.BranchReporting
            || this.mode === ManagementMode.BranchExcellence
            || this.mode === ManagementMode.Mersy && !node.Selected && node.Groups.filter(x => x.BusinessLevel !== 'FIELDBRANCH').length > 0
            || this.mode === ManagementMode.NIOPSReporting && node.Groups.some(child => this.searchIsNiOPSRelatedDescendants(child));

    }

    // Filter nodes based on certain conditions
    filterNode(node: MGMTBranchNode) {
        return (this.mode === ManagementMode.Mersy && node.BusinessLevel !== 'FIELDBRANCH')
            || ((this.mode === ManagementMode.BranchReporting || this.mode === ManagementMode.BranchExcellence)
                && (node.Groups?.filter(x => x.BusinessLevel === 'FIELDBRANCH') || node.BusinessLevel === 'FIELDBRANCH'))
            || (this.mode === ManagementMode.NIOPSReporting && node.IsNiOPSRelated === true);
    }

    // Update group function from dropdown
    async updateGrpFunfrmDrpDwn(node, grpFunc) {
        this.selGrpFuncFrmAll = grpFunc;
        this.itemToggle(true, node);
    }

    // Filter nodes by group function
    public filterNodesByGroupFunction(groupFunction: string) {
        this.arrayFiltered = [];
        this.treeControl.dataNodes.forEach((branchNode) => {
            if (branchNode.GroupFunction && this.groupFuncExist(groupFunction, branchNode)) {
                if (this.filterNode(branchNode)) this.arrayFiltered.push(branchNode);
            }
            if (branchNode.Groups?.length > 0) {
                const alldecendents = this.treeControl.getDescendants(branchNode);
                alldecendents.forEach((node) => {
                    if (node.GroupFunction && this.groupFuncExist(groupFunction, node)) {
                        if (this.filterNode(node)) this.arrayFiltered.push(node);
                    }
                });
            }
        });
        return this.arrayFiltered;
    }

    // Search for a node by value
    private searchNode(value: string, nodes: MGMTBranchNode[]): MGMTBranchNode | null {
        for (let node of nodes) {
            if (node.Value === value) {
                return node;
            }
            if (node.Groups) {
                const found = this.searchNode(value, node.Groups);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }

    // Recursive method to search for IsNiOPSRelated property
    private searchIsNiOPSRelatedDescendants(node: MGMTBranchNode): boolean {
        
        if (node.Groups && node.Groups.length > 0) {
            for (let childNode of node.Groups) {
                if (this.searchIsNiOPSRelatedDescendants(childNode)) {
                    return true;
                }
            }
        }       

        if (node.IsNiOPSRelated) {
            return true;
        }

        return false;
    }
}

export enum ManagementMode {
    Mersy = 0,
    BranchExcellence = 1,
    BranchReporting = 2,
    NIOPSReporting = 3
}