<div class="example-container-kpimanagement_opl_yearly black-checkbox new-table-style">

    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="KpiOPLMonthly">

        <!--</ng-container>-->
        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef>
                <mat-icon *ngIf="!isAllCollapsed" (click)="collapseOrExpandAll()">expand_more</mat-icon>
                <mat-icon *ngIf="isAllCollapsed" (click)="collapseOrExpandAll()">expand_less</mat-icon>
            </th>
            <td mat-cell *matCellDef="let account"
                (click)="pushPopElement(account)">
                <ng-container>
                    <span class="material-icons"
                          *ngIf="checkExpanded(account); else show_expand_more">
                        expand_less
                    </span>
                </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="Account">
            <th mat-header-cell *matHeaderCellDef> Account </th>
            <td mat-cell *matCellDef="let account">
                <mat-checkbox *ngIf="isReleaseActive || isRevokeActive" (click)="$event.stopPropagation()"
                              (change)="accountItemsSelected($event.checked,account);"
                              [checked]="accountCheckboxCheck(account)"
                              [indeterminate]="accountItemsIndetermine(account) && !accountCheckboxCheck(account)"
                              [disabled]="accountItemsDisable(account)"
                              matTooltip="Only yearly released kpis are allowed to be released in monthly basis"
                              [matTooltipDisabled]="isReleaseActive && !accountItemsDisable(account)">
                    <p [innerHTML]="account.AccountID | bold: dataSource.filter"></p>
                </mat-checkbox>
                <p *ngIf="!isReleaseActive && !isRevokeActive" [innerHTML]="account.AccountID | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="KpiName">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.KpiName | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <!--<ng-container matColumnDef="KpiPlan">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.KpiDescription | bold: dataSource.filter"></p>
            </td>
        </ng-container>-->

        <ng-container matColumnDef="KpiOwner">
            <th mat-header-cell *matHeaderCellDef> Owner </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.KpiOwner | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="KpiUnit">
            <th mat-header-cell *matHeaderCellDef> KpiUnit </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.KpiUnit | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="LocalCurrency">
            <th mat-header-cell *matHeaderCellDef> Local Currency </th>
            <td mat-cell *matCellDef="let account">
                <p *ngIf="account.KpiUnit === 'Values' || account.KpiUnit === 'Value LC'" [innerHTML]="account.LocalCurrency"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="AvailabilityTime">
            <th mat-header-cell *matHeaderCellDef> Availability Time </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.AvailabilityTime"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="ReportingType">
            <th mat-header-cell *matHeaderCellDef> Reporting Type </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.ReportingType"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let account">
                <button mat-icon-button matTooltip="KPI definition" (click)="onDetail(account)">
                    <mat-icon>search</mat-icon> <span>KPI definition</span>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let account" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(account) ? 'expanded' : 'collapsed'"
                     id="KPIMonthlyOPLTableDetail">

                    <table mat-table
                           [dataSource]="account.subtable"
                           class="mat-subtable-custom"
                           [ngClass]="account.maxBreakdown === 'COL4' ? 'details_four_breakdown' :
                                            account.maxBreakdown === 'COL3' ? 'details_three_breakdown' :
                                                    account.maxBreakdown === 'COL2' ? 'details_two_breakdown' :
                                                        account.maxBreakdown === 'COL1' ? 'details_one_breakdown' : 'details_no_breakdowns'">

                        <ng-container matColumnDef="ItemNo">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let account;index as i;">
                                <p [innerHTML]="(i+1)"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="MersyId">
                            <th mat-header-cell *matHeaderCellDef> KPI Code </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.MersyId"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="KpiName">
                            <th mat-header-cell *matHeaderCellDef> KPI Name </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.KpiName"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="COL1">
                            <th mat-header-cell *matHeaderCellDef> <p [innerHTML]="account.COL1"></p></th>
                            <td mat-cell (click)="expandedItemSelected(!selection.isSelected(account),account)" *matCellDef="let account" [ngClass]="{'clickable-cursor': isReleaseActive || isRevokeActive}">
                                <div class="defCheckbox">
                                    <mat-checkbox *ngIf="isReleaseActive || isRevokeActive" (click)="$event.stopPropagation();" (change)="expandedItemSelected($event.checked,account)"
                                                  [checked]="selection.isSelected(account)"
                                                  [disabled]="!(isReleaseActive ? (account.ApprovalStatus == 'PRE-RELEASED') : (account.ApprovalStatus == 'RELEASED'))"
                                                  matTooltip="Only yearly released kpis are allowed to be released in monthly basis"
                                                  [matTooltipDisabled]="isReleaseActive && (account.ApprovalStatus === 'PRE-RELEASED' || account.ApprovalStatus === 'RELEASED')">
                                        <!--(change)="expandedItemSelected($event.checked,account);"-->
                                        <!--<p [innerHTML]="account.COL1| bold: dataSource.filter"></p>-->
                                    </mat-checkbox>
                                    <p [innerHTML]="account.COL1"></p>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="COL2">
                            <th mat-header-cell *matHeaderCellDef> <p [innerHTML]="account.COL2"></p></th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.COL2"></p>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="COL3">
                            <th mat-header-cell *matHeaderCellDef> <p [innerHTML]="account.COL3"></p></th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.COL3"></p>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="COL4">
                            <th mat-header-cell *matHeaderCellDef> <p [innerHTML]="account.COL4"></p></th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.COL4"></p>
                            </td>
                        </ng-container>



                        <ng-container matColumnDef="BreakdownDescription1">
                            <th mat-header-cell *matHeaderCellDef>  </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.BreakdownDescription1"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="BreakdownDescription2">
                            <th mat-header-cell *matHeaderCellDef>  </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.BreakdownDescription2"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="MovementTypeDescription">
                            <th mat-header-cell *matHeaderCellDef>  </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.MovementTypeDescription"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="ProductHierarchyDescription">
                            <th mat-header-cell *matHeaderCellDef>  </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.ProductHierarchyDescription"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Granularity">
                            <th mat-header-cell *matHeaderCellDef> <p [innerHTML]="account.DetDescription"></p> </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.Granularity | bold: dataSource.filter"></p>
                            </td>
                        </ng-container>


                        <!--<ng-container matColumnDef="ACTPY">
                            <th mat-header-cell *matHeaderCellDef> Δ ACT/{{currentFY}} </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="formatCurrency(account.ACTPY, account.DecimalDigits)"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="ACTBUD">
                            <th mat-header-cell *matHeaderCellDef> Δ ACT/BUD </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="formatCurrency(account.ACTBUD, account.DecimalDigits)"></p>
                            </td>
                        </ng-container>-->

                        <ng-container matColumnDef="YTDACT">
                            <th mat-header-cell *matHeaderCellDef> YTD/ACT </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="formatCurrency(account.YTDACT, account.DecimalDigits)"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="PYBUD">
                            <th mat-header-cell *matHeaderCellDef> {{currentFY}}/BUD </th>
                            <td mat-cell *matCellDef="let breakdown">
                                <p [innerHTML]="formatCurrency(breakdown.PYBUD, account.DecimalDigits)"></p>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FY1BUD">
                            <th mat-header-cell *matHeaderCellDef> {{FY1}}/BUD </th>
                            <td mat-cell *matCellDef="let breakdown">
                                <p [innerHTML]="formatCurrency(breakdown.FY1BUD, account.DecimalDigits)"></p>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P1">
                            <th mat-header-cell *matHeaderCellDef> OCT ({{account.ReportingType}})</th>
                            <td mat-cell *matCellDef="let breakdown; indnex as i;" [ngClass]="{'released': breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="formatCurrency(breakdown.P1, account.DecimalDigits)" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' ? 'block' : 'none'"></p>

                                <input matInput [name]="(i+'P1')" [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' }" type="number" min="0" (keydown)="disableArrowKeys($event)"
                                       pattern="\d+(\.\d{1,2})?" [value]="breakdown.P1" [(ngModel)]="breakdown.P1"
                                       (ngModelChange)="onChange(account,breakdown,$event,'P1')">

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P2">
                            <th mat-header-cell *matHeaderCellDef> NOV ({{account.ReportingType}})</th>
                            <td mat-cell *matCellDef="let breakdown" [ngClass]="{'released': breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="formatCurrency(breakdown.P2, account.DecimalDigits)" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' ? 'block' : 'none'"></p>

                                <input matInput name="P2" [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' }" type="number" min="0" (keydown)="disableArrowKeys($event)"
                                       pattern="\d+(\.\d{1,2})?" [value]="breakdown.P2" [(ngModel)]="breakdown.P2"
                                       (ngModelChange)="onChange(account,breakdown,$event,'P2')">

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P3">
                            <th mat-header-cell *matHeaderCellDef> DEC ({{account.ReportingType}})</th>
                            <td mat-cell *matCellDef="let breakdown" [ngClass]="{'released': breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="formatCurrency(breakdown.P3, account.DecimalDigits)" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' ? 'block' : 'none'"></p>

                                <input matInput name="P3" [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' }" type="number" min="0" (keydown)="disableArrowKeys($event)"
                                       pattern="\d+(\.\d{1,2})?" [value]="breakdown.P3" [(ngModel)]="breakdown.P3"
                                       (ngModelChange)="onChange(account,breakdown,$event,'P3')">

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P4">
                            <th mat-header-cell *matHeaderCellDef> JAN ({{account.ReportingType}})</th>
                            <td mat-cell *matCellDef="let breakdown" [ngClass]="{'released': breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="formatCurrency(breakdown.P4, account.DecimalDigits)" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' ? 'block' : 'none'"></p>

                                <input matInput name="P4" [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' }" type="number" min="0" (keydown)="disableArrowKeys($event)"
                                       pattern="\d+(\.\d{1,2})?" [value]="breakdown.P4" [(ngModel)]="breakdown.P4"
                                       (ngModelChange)="onChange(account,breakdown,$event,'P4')">

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P5">
                            <th mat-header-cell *matHeaderCellDef> FEB ({{account.ReportingType}})</th>
                            <td mat-cell *matCellDef="let breakdown" [ngClass]="{'released': breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="formatCurrency(breakdown.P5, account.DecimalDigits)" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' ? 'block' : 'none'"></p>

                                <input matInput name="P5" [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' }" type="number" min="0" (keydown)="disableArrowKeys($event)"
                                       pattern="\d+(\.\d{1,2})?" [value]="breakdown.P5" [(ngModel)]="breakdown.P5"
                                       (ngModelChange)="onChange(account,breakdown,$event,'P5')">

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P6">
                            <th mat-header-cell *matHeaderCellDef> MAR ({{account.ReportingType}})</th>
                            <td mat-cell *matCellDef="let breakdown" [ngClass]="{'released': breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="formatCurrency(breakdown.P6, account.DecimalDigits)" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' ? 'block' : 'none'"></p>

                                <input matInput name="P6" [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' }" type="number" min="0" (keydown)="disableArrowKeys($event)"
                                       pattern="\d+(\.\d{1,2})?" [value]="breakdown.P6" [(ngModel)]="breakdown.P6"
                                       (ngModelChange)="onChange(account,breakdown,$event,'P6')">

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P7">
                            <th mat-header-cell *matHeaderCellDef> APR ({{account.ReportingType}})</th>
                            <td mat-cell *matCellDef="let breakdown" [ngClass]="{'released': breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="formatCurrency(breakdown.P7, account.DecimalDigits)" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' ? 'block' : 'none'"></p>

                                <input matInput name="P7" [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' }" type="number" min="0" (keydown)="disableArrowKeys($event)"
                                       pattern="\d+(\.\d{1,2})?" [value]="breakdown.P7" [(ngModel)]="breakdown.P7"
                                       (ngModelChange)="onChange(account,breakdown,$event,'P7')">

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P8">
                            <th mat-header-cell *matHeaderCellDef> MAY ({{account.ReportingType}})</th>
                            <td mat-cell *matCellDef="let breakdown" [ngClass]="{'released': breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="formatCurrency(breakdown.P8, account.DecimalDigits)" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' ? 'block' : 'none'"></p>

                                <input matInput name="P8" [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' }" type="number" min="0" (keydown)="disableArrowKeys($event)"
                                       pattern="\d+(\.\d{1,2})?" [value]="breakdown.P8" [(ngModel)]="breakdown.P8"
                                       (ngModelChange)="onChange(account,breakdown,$event,'P8')">

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P9">
                            <th mat-header-cell *matHeaderCellDef> JUN ({{account.ReportingType}})</th>
                            <td mat-cell *matCellDef="let breakdown" [ngClass]="{'released': breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="formatCurrency(breakdown.P9, account.DecimalDigits)" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' ? 'block' : 'none'"></p>

                                <input matInput name="P9" [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' }" type="number" min="0" (keydown)="disableArrowKeys($event)"
                                       pattern="\d+(\.\d{1,2})?" [value]="breakdown.P9" [(ngModel)]="breakdown.P9"
                                       (ngModelChange)="onChange(account,breakdown,$event,'P9')">

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P10">
                            <th mat-header-cell *matHeaderCellDef> JUL ({{account.ReportingType}})</th>
                            <td mat-cell *matCellDef="let breakdown" [ngClass]="{'released': breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="formatCurrency(breakdown.P10, account.DecimalDigits)" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' ? 'block' : 'none'"></p>

                                <input matInput name="P10" [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' }" type="number" min="0" (keydown)="disableArrowKeys($event)"
                                       pattern="\d+(\.\d{1,2})?" [value]="breakdown.P10" [(ngModel)]="breakdown.P10"
                                       (ngModelChange)="onChange(account,breakdown,$event,'P10')">

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P11">
                            <th mat-header-cell *matHeaderCellDef> AUG ({{account.ReportingType}})</th>
                            <td mat-cell *matCellDef="let breakdown" [ngClass]="{'released': breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="formatCurrency(breakdown.P11, account.DecimalDigits)" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' ? 'block' : 'none'"></p>

                                <input matInput name="P11" [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' }" type="number" min="0" (keydown)="disableArrowKeys($event)"
                                       pattern="\d+(\.\d{1,2})?" [value]="breakdown.P11" [(ngModel)]="breakdown.P11"
                                       (ngModelChange)="onChange(account,breakdown,$event,'P11')">

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P12">
                            <th mat-header-cell *matHeaderCellDef> SEP ({{account.ReportingType}})</th>
                            <td mat-cell *matCellDef="let breakdown" [ngClass]="{'released': breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="formatCurrency(breakdown.P12, account.DecimalDigits)" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' ? 'block' : 'none'"></p>

                                <input matInput name="P12" [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' }" type="number" min="0" (keydown)="disableArrowKeys($event)"
                                       pattern="\d+(\.\d{1,2})?" [value]="breakdown.P12" [(ngModel)]="breakdown.P12"
                                       (ngModelChange)="onChange(account,breakdown,$event,'P12')">

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef>Change Log</th>
                            <td mat-cell *matCellDef="let breakdown" class="notEditable">
                                <button *ngIf="(breakdown.P1 !== '' || breakdown.P2 !== '' || breakdown.P3 !== '' || breakdown.P4 !== '' || breakdown.P5 !== '' || breakdown.P6 !== '' 
                                        || breakdown.P7 !== '' || breakdown.P8 !== '' || breakdown.P9 !== '' || breakdown.P10 !== '' || breakdown.P11 !== '' || breakdown.P12 !== '') 
                                        && breakdown.ApprovalStatus !== ''" 
                                        mat-icon-button matTooltip="Get History" (click)="onDetail(breakdown)">
                                    <mat-icon>history</mat-icon> <span>Get History</span>
                                </button>
                            </td>
                        </ng-container>


                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="account.monthlyColumns"></tr>
                        <tr mat-row *matRowDef="let row2; let i2 = index; columns: account.monthlyColumns;"
                            class="subitem"
                            [class.alternative2]="isEditModeEnabled === true"></tr>
                    </table>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let account; let i = dataIndex; columns: columnsToDisplay;"
            class="item"
            [class.example-expanded-row]="checkExpanded(account)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="row-subitem"></tr>

    </table>
</div>

<ng-template #show_expand_more>
    <span class="material-icons">
        expand_more
    </span>
</ng-template>

<div *ngIf="isReleaseActive || isRevokeActive" class="stickypanel_KPI ">
    <div *ngIf="selection.hasValue()" class="col-lg-6" style="width:50%">
        Total selected breakdowns : {{selection.selected.length}}
    </div>
    <div *ngIf="!selection.hasValue()" class="col-lg-6" style="width:50%">
        Please select at least one Kpi / breakdown to {{isReleaseActive? 'Release' : 'Revoke'}} Selective or click {{isReleaseActive? 'Release' : 'Revoke'}} All
    </div>
    <div class="col-lg-6">
        <!--<button class="btn btn-realease btn-sm pull-right button-margin" (click)="isReleaseActive ? onClickReleaseMode_emitter(true):onClickRevokeMode_emitter(true) ">-->
        <!--<button class="btn btn-realease btn-sm pull-right button-margin" (click)="isReleaseActive ? onClickReleaseMode_emitter(true):onClickRevokeMode_emitter(true) ">
            {{ (isReleaseActive || isRevokeActive && isReleaseActive) ? 'Release ' : 'Revoke ' }} {{ !selection.hasValue() ? 'All' : 'Selected' }}
        </button>-->
        <!--<button class="btn btn-realease btn-sm pull-right button-margin" (click)="onClickReleaseMode_emitter(true)" [disabled]="!selection.hasValue()">
            {{ (isReleaseActive || isRevokeActive && isReleaseActive) ? 'Release Selected' : 'Revoke Selected' }}
        </button>-->
        <!--<div class="new-table-style">
            <a class='kpi-button-menu pull-right customchangesbutton' title="Revoke all released values" (click)="isReleaseActive ? onClickReleaseMode_emitter(false):onClickRevokeMode_emitter(false)">
                {{ (isReleaseActive || isRevokeActive && isReleaseActive) ? 'Release ' : 'Revoke ' }} {{ !selection.hasValue() ? 'All' : 'Selected' }}
            </a>
        </div>-->


        <button class="btn btn-realease btn-sm pull-right button-margin" [disabled]="!anyActionableItemExist()" (click)="isReleaseActive ? onClickReleaseMode_emitter(false):onClickRevokeMode_emitter(false)">
            {{ textRelRevbuttonState() }}
        </button>


    </div>
</div>