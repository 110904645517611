import { Component, Input, OnInit, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { DefaultValuesService } from 'src/app/services/defaultvalues.service';
import { EquipmentManagenementService } from 'src/app/services/equipmentmanagement.service';
import { SoftwareReleaseListComponent } from './components/software-release-list/software-release-list.component';
import { forkJoin, map, takeUntil } from 'rxjs';
import { SoftwareReleaseItem } from './types/software-release';
import { EmtAllowedSoftwareReleaseLevelDto, EmtAllowedSoftwareReleaseType } from 'src/app/interfaces/emt/emt-allowed-softwarer-release';
import { DestroyService } from 'src/app/services/destroyservice';

@Component({
  selector: 'softwarereleasetofield',
  templateUrl: './softwarereleasetofield.component.html',
  styleUrls: ['./softwarereleasetofield.component.less', '../../../style/new-generic-styles.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService]
})
export class SoftwareReleaseToFieldComponent implements OnInit {
  @ViewChild(SoftwareReleaseListComponent)
  private list!: SoftwareReleaseListComponent;
  @Input()
  public availableFamilyCodes: FamilyCode[] = [];
  public familyCodes: Map<number, string> = new Map();
  public softwareReleases: SoftwareReleaseItem[] = [];

  constructor(
    private emtService: EquipmentManagenementService,
    public defaults: DefaultValuesService,
    @Self() private readonly destroy$: DestroyService
  ) { }

  public ngOnInit() {
    this.loadSoftwareReleases();

    this.familyCodes = new Map(
      this.availableFamilyCodes.map(x => [x.familyCodeId, x.familyCode]));

    this.defaults.stageMessageChanges$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.loadSoftwareReleases());
  }

  public onNewLevelSelected(level: EmtAllowedSoftwareReleaseLevelDto) {
    this.list.changeSelectedLevel(level);
  }

  private loadSoftwareReleases() {
    const controllerSoftwares$ = this.emtService.getControllerSoftwareReleases().pipe(
      map(response => response?.releasesList ?? []),
      map(list => list.map(x => ({
        id: x.controllerSoftwareVersionID,
        type: EmtAllowedSoftwareReleaseType.Controller,
        version: x.controllerSoftwareVersion,
        familyCodeId: x.familyCodeId,
      }) as SoftwareReleaseItem)),
    );

    const peripheralSoftwares$ = this.emtService.getPeriphealSoftwareReleases().pipe(
      map(x => x?.versionList ?? []),
      map(list => list.map(x => ({
        id: x.peripheralSoftwareVersionID,
        type: EmtAllowedSoftwareReleaseType.Peripheral,
        version: x.peripheralSoftwareVersion,
        familyCodeId: x.familyCodeId,
      }) as SoftwareReleaseItem)),
    );

    forkJoin([controllerSoftwares$, peripheralSoftwares$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([controllerSoftwares, peripheralSoftwares]) => {
        this.softwareReleases = [...controllerSoftwares, ...peripheralSoftwares];
      });
  }
}
