<div class="
    black-checkbox
    new-table-style">

    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="MeasureImpactTable">

        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" (click)="pushPopElement(element)">
                <span class="material-icons" *ngIf="checkExpanded(element); else show_expand_more">
                    expand_less
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="ItemNo">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let measure">
                <p [innerHTML]="measure.ItemNo"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let measure">
                <p [innerHTML]="measure.Description | bold: dataSource.filter"></p>
            </td>
        </ng-container>


        <ng-container matColumnDef="Kpi">
            <th mat-header-cell *matHeaderCellDef> Kpi </th>
            <td mat-cell *matCellDef="let measure">
                <p [innerHTML]="measure.KpiName"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="KpiDescription">
            <th mat-header-cell *matHeaderCellDef> Kpi Description </th>
            <td mat-cell *matCellDef="let measure">
                <p [innerHTML]="measure.KpiDescription"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="BreakdownSelector">
            <th mat-header-cell *matHeaderCellDef> Breakdown </th>
            <td mat-cell *matCellDef="let measure">
                <div *ngIf="!showDropdown(measure.BreakdownSelector) ">No Breakdown</div>
                <ng-select
                           *ngIf="showDropdown(measure.BreakdownSelector)"
                           class="integratedNgSelect"
                           [items]="measure.BreakdownSelector"
                           bindLabel="label"
                           bindValue="id"
                           [clearable]="false"
                           [searchable]="false"
                           [clearOnBackspace]="false"
                           [(ngModel)]="measure.SelectedBreakdown"
                           (change)="onSelect($event, measure.KpiDefinitionId)">
                </ng-select>
            </td>
        </ng-container>

        <ng-container matColumnDef="PeriodSelector">
            <th mat-header-cell *matHeaderCellDef> Period </th>
            <td mat-cell *matCellDef="let measure">
                <ng-select [items]="measure.PeriodSelector"
                           bindValue="id"
                           bindLabel="name"
                           [(ngModel)]="measure.SelectedPeriod"
                           class="integratedNgSelect"
                           [clearable]="false"
                           [searchable]="false"
                           (change)="onSelectPeriod($event, measure.KpiDefinitionId)">
                </ng-select>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="actionitems">
            <td mat-cell *matCellDef="let measure" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(measure) ? 'expanded' : 'collapsed'"
                     id="MeasureImpactTargetSubtable">
                    <p style="color:white">{{fiscalYearDisplay}}</p>
                    <table mat-table
                           [dataSource]="measure.Subtables[measure.SelectedBreakdown]"
                           class="mat-subtable-custom">

                        <ng-container matColumnDef="item">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let item; index as i;">
                                <p [innerHTML]="item.item"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="P1">
                            <th mat-header-cell *matHeaderCellDef> OCT </th>
                            <td mat-cell *matCellDef="let item; index as i;">
                                <p [innerHTML]="item.P1" [style.display]="isEditableFieldEnabled(item.item) ? 'none' : 'block'"></p>
                                <p [style.display]="item.item !== 'Target' ? 'none' : 'block'"
                                   [innerHTML]="getTarget(measure.Subtables,measure.SelectedBreakdown,'P1')"></p>
                                <input matInput type="number" min="0"
                                       [ngClass]="{'hidden': !isEditableFieldEnabled(item.item) }"
                                       (ngModelChange)="onChange(measure, 1, item.MeasureTargetP1Id, $event)"
                                       [(ngModel)]="item.P1"
                                       (input)="validateInput(item.item, $event)">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="P2">
                            <th mat-header-cell *matHeaderCellDef> NOV </th>
                            <td mat-cell *matCellDef="let item; index as i;">
                                <p [innerHTML]="item.P2" [style.display]="isEditableFieldEnabled(item.item) ? 'none' : 'block'"></p>
                                <p [style.display]="item.item !== 'Target' ? 'none' : 'block'"
                                   [innerHTML]="getTarget(measure.Subtables,measure.SelectedBreakdown,'P2')"></p>
                                <input matInput type="number" min="0"
                                       [ngClass]="{'hidden': !isEditableFieldEnabled(item.item) }"
                                       (ngModelChange)="onChange(measure,2, item.MeasureTargetP2Id, $event)"
                                       [(ngModel)]="item.P2"
                                       (input)="validateInput(item.item, $event)">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P3">
                            <th mat-header-cell *matHeaderCellDef> DEC </th>
                            <td mat-cell *matCellDef="let item; index as i;">
                                <p [innerHTML]="item.P3" [style.display]="isEditableFieldEnabled(item.item) ? 'none' : 'block'"></p>
                                <p [style.display]="item.item !== 'Target' ? 'none' : 'block'"
                                   [innerHTML]="getTarget(measure.Subtables,measure.SelectedBreakdown,'P3')"></p>
                                <input matInput type="number" min="0"
                                       [ngClass]="{'hidden': !isEditableFieldEnabled(item.item) }"
                                       (ngModelChange)="onChange(measure,3, item.MeasureTargetP3Id, $event)"
                                       [(ngModel)]="item.P3"
                                       (input)="validateInput(item.item, $event)">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P4">
                            <th mat-header-cell *matHeaderCellDef> JAN </th>
                            <td mat-cell *matCellDef="let item; index as i;">
                                <p [innerHTML]="item.P4" [style.display]="isEditableFieldEnabled(item.item) ? 'none' : 'block'"></p>
                                <p [style.display]="item.item !== 'Target' ? 'none' : 'block'"
                                   [innerHTML]="getTarget(measure.Subtables,measure.SelectedBreakdown,'P4')"></p>
                                <input matInput type="number" min="0"
                                       [ngClass]="{'hidden': !isEditableFieldEnabled(item.item) }"
                                       (ngModelChange)="onChange(measure,4, item.MeasureTargetP4Id, $event)"
                                       [(ngModel)]="item.P4"
                                       (input)="validateInput(item.item, $event)">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P5">
                            <th mat-header-cell *matHeaderCellDef> FEB </th>
                            <td mat-cell *matCellDef="let item; index as i;">
                                <p [innerHTML]="item.P5" [style.display]="isEditableFieldEnabled(item.item) ? 'none' : 'block'"></p>
                                <p [style.display]="item.item !== 'Target' ? 'none' : 'block'"
                                   [innerHTML]="getTarget(measure.Subtables,measure.SelectedBreakdown,'P5')"></p>
                                <input matInput type="number" min="0"
                                       [ngClass]="{'hidden': !isEditableFieldEnabled(item.item) }"
                                       (ngModelChange)="onChange(measure,5, item.MeasureTargetP5Id, $event)"
                                       [(ngModel)]="item.P5"
                                       (input)="validateInput(item.item, $event)">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P6">
                            <th mat-header-cell *matHeaderCellDef> MAR </th>
                            <td mat-cell *matCellDef="let item; index as i;">
                                <p [innerHTML]="item.P6" [style.display]="isEditableFieldEnabled(item.item) ? 'none' : 'block'"></p>
                                <p [style.display]="item.item !== 'Target' ? 'none' : 'block'"
                                   [innerHTML]="getTarget(measure.Subtables,measure.SelectedBreakdown,'P6')"></p>
                                <input matInput type="number" min="0"
                                       [ngClass]="{'hidden': !isEditableFieldEnabled(item.item) }"
                                       (ngModelChange)="onChange(measure,6, item.MeasureTargetP6Id, $event)"
                                       [(ngModel)]="item.P6"
                                       (input)="validateInput(item.item, $event)">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P7">
                            <th mat-header-cell *matHeaderCellDef> APR </th>
                            <td mat-cell *matCellDef="let item; index as i;">
                                <p [innerHTML]="item.P7" [style.display]="isEditableFieldEnabled(item.item) ? 'none' : 'block'"></p>
                                <p [style.display]="item.item !== 'Target' ? 'none' : 'block'"
                                   [innerHTML]="getTarget(measure.Subtables,measure.SelectedBreakdown,'P7')"></p>
                                <input matInput type="number" min="0"
                                       [ngClass]="{'hidden': !isEditableFieldEnabled(item.item) }"
                                       (ngModelChange)="onChange(measure,7, item.MeasureTargetP7Id, $event)"
                                       [(ngModel)]="item.P7"
                                       (input)="validateInput(item.item, $event)">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P8">
                            <th mat-header-cell *matHeaderCellDef> MAY </th>
                            <td mat-cell *matCellDef="let item; index as i;">
                                <p [innerHTML]="item.P8" [style.display]="isEditableFieldEnabled(item.item) ? 'none' : 'block'"></p>
                                <p [style.display]="item.item !== 'Target' ? 'none' : 'block'"
                                   [innerHTML]="getTarget(measure.Subtables,measure.SelectedBreakdown,'P8')"></p>
                                <input matInput type="number" min="0"
                                       [ngClass]="{'hidden': !isEditableFieldEnabled(item.item) }"
                                       (ngModelChange)="onChange(measure,8, item.MeasureTargetP8Id, $event)"
                                       [(ngModel)]="item.P8"
                                       (input)="validateInput(item.item, $event)">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P9">
                            <th mat-header-cell *matHeaderCellDef> JUN </th>
                            <td mat-cell *matCellDef="let item; index as i;">
                                <p [innerHTML]="item.P9" [style.display]="isEditableFieldEnabled(item.item) ? 'none' : 'block'"></p>
                                <p [style.display]="item.item !== 'Target' ? 'none' : 'block'"
                                   [innerHTML]="getTarget(measure.Subtables,measure.SelectedBreakdown,'P9')"></p>
                                <input matInput type="number" min="0"
                                       [ngClass]="{'hidden': !isEditableFieldEnabled(item.item) }"
                                       (ngModelChange)="onChange(measure,9, item.MeasureTargetP9Id, $event)"
                                       [(ngModel)]="item.P9"
                                       (input)="validateInput(item.item, $event)">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P10">
                            <th mat-header-cell *matHeaderCellDef> JUL </th>
                            <td mat-cell *matCellDef="let item; index as i;">
                                <p [innerHTML]="item.P10" [style.display]="isEditableFieldEnabled(item.item) ? 'none' : 'block'"></p>
                                <p [style.display]="item.item !== 'Target' ? 'none' : 'block'"
                                   [innerHTML]="getTarget(measure.Subtables,measure.SelectedBreakdown,'P10')"></p>
                                <input matInput type="number" min="0"
                                       [ngClass]="{'hidden': !isEditableFieldEnabled(item.item) }"
                                       (ngModelChange)="onChange(measure,10, item.MeasureTargetP10Id, $event)"
                                       [(ngModel)]="item.P10"
                                       (input)="validateInput(item.item, $event)">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P11">
                            <th mat-header-cell *matHeaderCellDef> AUG </th>
                            <td mat-cell *matCellDef="let item; index as i;">
                                <p [innerHTML]="item.P11" [style.display]="isEditableFieldEnabled(item.item) ? 'none' : 'block'"></p>
                                <p [style.display]="item.item !== 'Target' ? 'none' : 'block'"
                                   [innerHTML]="getTarget(measure.Subtables,measure.SelectedBreakdown,'P11')"></p>
                                <input matInput type="number" min="0"
                                       [ngClass]="{'hidden': !isEditableFieldEnabled(item.item) }"
                                       (ngModelChange)="onChange(measure,11, item.MeasureTargetP11Id, $event)"
                                       [(ngModel)]="item.P11"
                                       (input)="validateInput(item.item, $event)">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="P12">
                            <th mat-header-cell *matHeaderCellDef> SEP </th>
                            <td mat-cell *matCellDef="let item; index as i;">
                                <p [innerHTML]="item.P12" [style.display]="isEditableFieldEnabled(item.item) ? 'none' : 'block'"></p>
                                <p [style.display]="item.item !== 'Target' ? 'none' : 'block'"
                                   [innerHTML]="getTarget(measure.Subtables,measure.SelectedBreakdown,'P12')"></p>
                                <input matInput type="number"
                                       [ngClass]="{'hidden': !isEditableFieldEnabled(item.item) }"
                                       (ngModelChange)="onChange(measure,12, item.MeasureTargetP12Id, $event)"
                                       [(ngModel)]="item.P12"
                                       (input)="validateInput(item.item, $event)">
                            </td>
                        </ng-container>


                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="measure.subColumnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let item; let i2 = index; columns: measure.subColumnsToDisplay;" class="subitem"
                            [class.editable]="getIsEditModeEnabled() && item.item === 'Target %'"
                            [class.noneditable]="getIsEditModeEnabled() && item.item !== 'Target %'"></tr>
                    </table>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let measure; let i = dataIndex; columns: columnsToDisplay;"
            class="item">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['actionitems']" class="row-subitem"></tr>
    </table>
</div>

<ng-template #show_expand_more>
    <span class="material-icons" matTooltip="Show Action Items">
        expand_more
    </span>
</ng-template>