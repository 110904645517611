<div class="levels">
  <div class="row mt-5 row-headers" style="background-color: black;height: 90px; padding-left: 5px;padding-right: 5px;">
    <div class="col-12" style="text-align:center;">
      <h4>Level</h4>
    </div>
    <div class="col-12 search-container">
      <input class="form-control" [(ngModel)]="searchString" type="text" (keyup)="treeControl.expandAll()"
        placeholder="Search" />
      <mat-slide-toggle matTooltip="Show only selected" class="toggle-show-only-selected"
        [(ngModel)]="showOnlySelected"></mat-slide-toggle>
      <button aria-label="Collapse All" class="btn btn-sm button-color-fix " (click)="treeControl.collapseAll()"
        matTooltip="Collapse All">
        <mat-icon>unfold_less</mat-icon>
      </button>
      <button aria-label="Expand All" class="btn btn-sm button-color-fix " (click)="treeControl.expandAll()"
        matTooltip="Expand All">
        <mat-icon>unfold_more</mat-icon>
      </button>
      <button aria-label="Add level" class="btn btn-sm button-color-fix " (click)="addNewLevel()"
        matTooltip="Add level">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  <div class="custom_small_panelbody_fix_ghome">
    <div *ngIf="dataSource.data" class="example-tree">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree"
        style="padding-top: 10px; height: 100%;">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle
          [style.display]="(showOnlySelected && !(node.selected)) || (this.searchString && hideLeafNode(node)) ? 'none' : 'block'">
          <li class="mat-tree-node">
            <mat-checkbox *ngIf="node.level" [checked]="node.selected" [disabled]="true" (click)="onSelectNode(node)">
              {{node.text}}
            </mat-checkbox>
            <p *ngIf="!node.level">{{node.text}}</p>
          </li>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild"
          [style.display]="(showOnlySelected && !(node.selected)) || (this.searchString && hideParentNode(node)) ? 'none' : 'block'">
          <li>
            <div class="mat-tree-node">
              <mat-checkbox *ngIf="node.level" [checked]="node.selected" [disabled]="true" (click)="onSelectNode(node)">
                {{node.text}}
              </mat-checkbox>
              <p *ngIf="!node.level">{{node.text}}</p>
              <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" style="width:30px">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
            </div>
            <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
              <ng-container matTreeNodeOutlet></ng-container>
            </ul>
          </li>
        </mat-nested-tree-node>
      </mat-tree>
    </div>
  </div>
</div>