import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output, Self, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ToastrService } from 'ngx-toastr';
import { catchError, firstValueFrom, takeUntil, tap, throwError } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';
import { EquipmentManagenementService } from 'src/app/services/equipmentmanagement.service';
import { AddSoftwareReleaseLevelComponent } from '../add-software-release-level/add-software-release-level.component';
import { EmtAllowedSoftwareReleaseLevelDto } from 'src/app/interfaces/emt/emt-allowed-softwarer-release';
import { DestroyService } from 'src/app/services/destroyservice';
import { DefaultValuesService } from 'src/app/services/defaultvalues.service';

interface LevelNode {
  selected?: boolean;
  expandable?: boolean;
  parent?: LevelNode;
  level?: EmtAllowedSoftwareReleaseLevelDto;
  id: string;
  text: string;
  children: LevelNode[];
}

@Component({
  selector: 'software-release-levels',
  templateUrl: './software-release-levels.component.html',
  styleUrls: ['./software-release-levels.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService]
})
export class SoftwareReleaseLevelsComponent implements OnInit {
  @Output()
  public onLevelSelectedTrigger = new EventEmitter<EmtAllowedSoftwareReleaseLevelDto>();
  public selectedLevel: EmtAllowedSoftwareReleaseLevelDto | undefined;
  public treeData: LevelNode[] = [];
  public treeControl = new NestedTreeControl<LevelNode>((node) => node.children);
  public dataSource = new MatTreeNestedDataSource<LevelNode>();
  public searchString = '';
  public showOnlySelected = false;
  public levels: EmtAllowedSoftwareReleaseLevelDto[] = [];

  constructor(
    private emtService: EquipmentManagenementService,
    private dialogForm: MatDialog,
    private dialog: DialogService,
    private toast: ToastrService,
    public readonly defaults: DefaultValuesService,
    @Self() private readonly destroy$: DestroyService
  ) {
  }

  public ngOnInit() {
    this.loadLevels();

    this.defaults.stageMessageChanges$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.loadLevels());
  }

  public hasChild(_: number, node: LevelNode) {
    return !!node.children?.length;
  }

  public async addNewLevel() {
    const dialogRef = this.dialogForm.open(AddSoftwareReleaseLevelComponent, {
      disableClose: true,
      width: '850px',
      panelClass: ['custom-mat-dialog_v2'],
      data: {},
    });

    const level = await firstValueFrom(dialogRef.componentInstance.onLevelInitializedTrigger)
    await this.loadLevels()

    const selectionNode = this.findNode(level);
    if (selectionNode) {
      this.onYes(selectionNode);
      this.expandTreeToNode(selectionNode);
    }
  }

  private loadLevels() {
    this.selectedLevel = undefined;
    return firstValueFrom(
      this.emtService.getSoftwareReleaseLevels()
        .pipe(
          takeUntil(this.destroy$),
          tap(response => {
            this.levels = response;
            this.updateTreeFromSource();
          }),
          catchError(error => {
            this.toast.error('Unable to load levels.');
            return throwError(() => error);
          })
        )
    )
  }

  private findNode(level: EquipmentConfigurationLevelDetail) {
    if (this.emtService.isEnvironment(level)) {
      return this.treeData.find(x => x.id.toLowerCase() === level.environment);
    }
    else if (this.emtService.isTenant(level)) {
      return this.treeData.find(x => x.id.toLowerCase() === level.environment)?.children.find(y => y.id === level.tenantId!);
    }

    return null;
  }

  private expandTreeToNode(node: LevelNode): void {
    if (node.parent) {
      this.expandTreeToNode(node.parent);
    }

    this.treeControl.expand(node);
  }

  public onSelectNode(node: LevelNode) {
    if (!node.level || node.level === this.selectedLevel) {
      return;
    }

    if (this.selectedLevel) {
      const levelName = `${node.level.environment.toUpperCase()} ${node.level.tenantId ? ` - ${node.level.tenantId}` : ''}`;
      this.dialog.showConfirmDialog(
        `Do you want to change to the level ${levelName}?`,
        "Confirm",
        this,
        node);
    } else {
      this.onYes(node);
    }
  }

  public onYes(node: LevelNode) {
    this.unselectPrevious();
    this.selectedLevel = node.level;
    this.selectNode(node);
    this.onLevelSelectedTrigger.emit(node.level);
  }

  public hideLeafNode(node: LevelNode): boolean {
    return new RegExp(this.searchString, 'i').test(node.text) === false;
  }

  public hideParentNode(node: LevelNode): boolean {
    if (
      !this.searchString ||
      node.text.toLowerCase().indexOf(this.searchString.toLowerCase()) !==
      -1
    ) {
      return false
    }
    const descendants = this.treeControl.getDescendants(node)

    return !(descendants.some(
      (descendantNode) =>
        descendantNode.text
          .toLowerCase()
          .indexOf(this.searchString.toLowerCase()) !== -1));
  }

  private updateTreeFromSource(): void {
    const nodeRoots: LevelNode[] = [];

    this.levels.forEach(level => {
      const environment = this.getOrCreateNode(nodeRoots, level.environment, level.environment.toUpperCase(), this.getEnvironmentData(level));
      if (level.tenantId) {
        this.getOrCreateNode(environment.children, level.tenantId!, level.tenantId!, level, environment);
      }
    });

    this.treeData = nodeRoots;
    this.treeControl.dataNodes = this.treeData;
    this.dataSource.data = this.treeData;
  }

  private getEnvironmentData(level: EmtAllowedSoftwareReleaseLevelDto) {
    return level.tenantId
      ? { environment: level.environment }
      : level;
  }

  private createNode(
    id: string,
    text: string,
    detail?: EmtAllowedSoftwareReleaseLevelDto,
    parent?: LevelNode): LevelNode {
    return {
      id,
      text,
      level: detail,
      children: [],
      parent: parent
    };
  }

  private selectNode(node?: LevelNode) {
    if (node) {
      node.selected = true;
      this.selectNode(node.parent);
    }
  }

  private unselectPrevious() {
    this.treeData.forEach(x => {
      this.unselectTree(x);
    });
  }

  private unselectTree(node: LevelNode) {
    node.selected = false;
    node.children.forEach(x => {
      this.unselectTree(x);
    });
  }

  private getOrCreateNode(
    nodes: LevelNode[],
    id: string,
    name: string,
    levelData?: EmtAllowedSoftwareReleaseLevelDto,
    parent?: LevelNode): LevelNode {
    let node = nodes.find(n => n.id === id);

    if (!node) {
      node = this.createNode(id, name, levelData, parent);
      nodes.push(node);
    }

    return node;
  }
}
