import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { KpiAdminManagementService } from '../../../../../services/kpiadminmanagement.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-kpiaccount-historydialog',
  templateUrl: './kpiaccount-historydialog.component.html',
    styleUrls: ['./kpiaccount-historydialog.component.less',
'../../../../../style/changelog-dialog/changelog-dialog.less']
})
export class KpiAccountHistoryPopup implements OnInit {

    public dataSource = new MatTableDataSource<KpiAccountChanges>();
    searchString: string = '';
    targetKPI: string = '';
    originalData: KpiAccountChanges[] = [];
    @ViewChild(MatSort) matSort = new MatSort();
    @ViewChild('tableBody') tableBody!: ElementRef;
    isOpenTextAreaOverlay: boolean = false;

    constructor(
        private kpiAdminService: KpiAdminManagementService,
        private dialogRef: MatDialogRef<KpiAccountHistoryPopup>,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public datePipe: DatePipe,
        private toast: ToastrService    ) { }

    columnsToDisplay = ["date", "upn", "details"];

    ngOnInit(): void {
        this.searchString = '';
        this.originalData = [];

        this.kpiAdminService.getKpiAccountHistory(
            this.data.KpiDefinitionId,
            (response: KpiAccountHistory) => {
                if (response) {
                    this.tableBody.nativeElement.style.visibility = "visible";
                    this.targetKPI = response.targetKpiAccount;
                    this.dataSource.data = response.changesList;
                    this.originalData = response.changesList;
                }
            },
            error => {
                this.toast.error('Unable to load KPI account history.');
                this.tableBody.nativeElement.style.visibility = "hidden";
            }
        );
  }

    ngAfterViewInit() {
        this.dataSource.sort = this.matSort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'date':
                    return new Date(item.utcDate);
                default:
                    return item[property];
            }
        };
    }

    close() {
        this.dialogRef.close();
    }

    async updateFilteredHistory() {
        this.dataSource.data = this.filterAndShowResults(this.searchString);
    }

    filterAndShowResults(searchString: string) {
        // If the search string is empty, show all items
        if (searchString === "") {
            return this.originalData;
        }
        return this.originalData.filter(item => searchString === "" ? true : item.upn.toLowerCase().includes(searchString.toLowerCase())
            || item.details.toLowerCase().includes(searchString.toLowerCase()));
    }
}
