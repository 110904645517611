import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ToastrService } from 'ngx-toastr';
import { AddLevelComponent } from 'src/app/components/emtconfiguration/addlevel/addlevel.component';
import { DialogService } from 'src/app/services/dialog.service';
import { EquipmentManagenementService } from 'src/app/services/equipmentmanagement.service';

interface LevelNode {
  selected?: boolean;
  expandable?: boolean;
  parent?: LevelNode;

  level?: EquipmentConfigurationLevelDetail;

  id: string;
  text: string;
  children: LevelNode[];
}


@Component({
  selector: 'levelhierarchy',
  templateUrl: './levelhierarchy.component.html',
  styleUrls: ['./levelhierarchy.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class LevelHierarchyComponent implements OnInit {

  @Output()
  public onLevelSelectedTrigger = new EventEmitter<EquipmentConfigurationLevel>();
  public selectedLevel: EquipmentConfigurationLevel | undefined;
  public treeData: LevelNode[] = [];
  public treeControl = new NestedTreeControl<LevelNode>((node) => node.children);
  public dataSource = new MatTreeNestedDataSource<LevelNode>();
  private userLevels: EquipmentConfigurationLevelDetail[] = [];
  //Filters
  public searchString = '';
  public showOnlySelected = false;
  //UI
  private dialogRef: MatDialogRef<AddLevelComponent, any> | undefined

  constructor(
    private emtService: EquipmentManagenementService,
    private dialogForm: MatDialog,
    private dialog: DialogService,
    private toast: ToastrService
  ) {
  }

  ngOnInit() {

    this.loadUserLevels();
  }

  private loadUserLevels(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.emtService.getUserLevels(
        response => {
          this.userLevels = response;
          this.updateTreeFromSource();
          resolve();
        },
        error => {
          this.toast.error('Unable to load user levels.');
          reject(error);
        }
      );
    });
  }

  hasChild =
    (_: number, node: LevelNode) =>
      !!node.children && node.children.length > 0;

  addNewLevel(): void {
    this.dialogRef = this.dialogForm.open(AddLevelComponent, {
      disableClose: true,
      width: '850px',
      panelClass: ['custom-mat-dialog_v2'],
      data: {},
    });
    this.dialogRef.componentInstance.onLevelInitializedTrigger
      .subscribe((level: EquipmentConfigurationLevel) => {
        this.loadUserLevels().then(() => {
          // Find created level
          let selectionNode = this.findNode(level);
          if (selectionNode) {
            this.onYes(selectionNode);
            this.expandTreeToNode(selectionNode);
          }
        }).catch(error => {
          console.error('Error loading user levels');
        });
      });
  }

  private findNode(level: EquipmentConfigurationLevelDetail) {
    if (this.emtService.isEnvironment(level)) {
      return this.treeData.filter(x => x.id.toLowerCase() === level.environment)[0];
    }
    else if (this.emtService.isTenant(level)) {
      return this.treeData.filter(x => x.id.toLowerCase() === level.environment)[0].children.filter(y => y.id === level.tenantId!)[0];
    }
    else if (this.emtService.isBranch(level)) {
      return this.treeData.filter(x => x.id.toLowerCase() === level.environment)[0].children.filter(y => y.id === level.tenantId!)[0].children.filter(w => w.id === level.branchNumber!)[0];
    }
    else if (this.emtService.isDevice(level)) {
      return this.treeData.filter(x => x.id.toLowerCase() === level.environment)[0].children.filter(y => y.id === level.tenantId!)[0].children.filter(w => w.id === level.branchNumber!)[0].children.filter(z => z.id === level.deviceId!)[0];
    }
    return null;
  }

  private expandTreeToNode(node: LevelNode): void {
    if (node.parent) {
      this.expandTreeToNode(node.parent);
    }
    this.treeControl.expand(node);
  }

  onSelectNode(node: LevelNode) {
    if (!node.level || node.level === this.selectedLevel) {
      return;
    }
    this.dialog.showConfirmDialog(
      "Do you want to change to the level " +
      this.levelToString(node.level) + "?",
      "Confirm",
      this,
      node);
  }

  onYes(node: LevelNode) {
    this.unSelectPrevious();
    this.selectedLevel = node.level;
    this.selectNode(node);
    this.onLevelSelectedTrigger.emit(node.level);
  }

  unSelectPrevious() {
    this.treeData.forEach(x => {
      this.unSelectTree(x);
    });
  }
  selectNode(node?: LevelNode) {
    if (!node)
      return

    node.selected = true;
    this.selectNode(node.parent);
  }

  unSelectTree(node: LevelNode) {
    node.selected = false;
    node.children.forEach(x => {
      this.unSelectTree(x);
    });
  }

  public hideLeafNode(node: LevelNode): boolean {
    return new RegExp(this.searchString, 'i').test(node.text) === false;
  }

  public hideParentNode(node: LevelNode): boolean {
    if (
      !this.searchString ||
      node.text.toLowerCase().indexOf(this.searchString.toLowerCase()) !==
      -1
    ) {
      return false
    }
    const descendants = this.treeControl.getDescendants(node)

    return !(descendants.some(
      (descendantNode) =>
        descendantNode.text
          .toLowerCase()
          .indexOf(this.searchString.toLowerCase()) !== -1));
  }

  private levelToString(level: EquipmentConfigurationLevelDetail): string {
    let result = "( ";
    result += `Environment: ${level.environment}`;
    result += level.tenantId ? `, Tenant: ${level.tenantId}` : '';
    result += level.branchNumber ? `, Branch: ${level.branchNumber}` : '';
    result += level.deviceId ? `, Device: ${level.deviceId}` : '';
    result += " )";
    return result;
  }


  //Tree related methods
  private updateTreeFromSource(): void {
    const nodeRoots: LevelNode[] = [];

    this.userLevels.forEach(level => {
      const environment = this.getOrCreateNode(nodeRoots, level.environment, level.environment, this.getEnvironmentData(level));
      //const tenant = this.getOrCreateNode(environment.children, level.tenantId!, level.tenantId!, this.getTenantData(level), environment);

      if (this.emtService.isTenantBranchOrDevice(level)) {
        const tenant = this.getOrCreateNode(environment.children, level.tenantId!, level.tenantId!, this.getTenantData(level), environment);
        if (this.emtService.isBranch(level)) {
          const branch = this.getOrCreateNode(tenant.children, level.branchNumber!, level.branchFullName!, this.getBranchData(level), tenant);
          if (this.emtService.isDevice(level)) {
            this.getOrCreateNode(branch.children, level.deviceId!, level.deviceId!, level, branch);
          }
        }
      }
    });

    this.treeData = nodeRoots;
    this.treeControl.dataNodes = this.treeData;
    this.dataSource.data = this.treeData;
  }

  private getEnvironmentData(level: EquipmentConfigurationLevelDetail): EquipmentConfigurationLevelDetail {
    return this.emtService.isEnvironment(level) ? level : {
      environment: level.environment,
    } as EquipmentConfigurationLevelDetail;
  }

  private getTenantData(level: EquipmentConfigurationLevelDetail): EquipmentConfigurationLevelDetail {
    return this.emtService.isTenant(level) ? level : {
      environment: level.environment,
      tenantId: level.tenantId
    } as EquipmentConfigurationLevelDetail;
  }

  private getBranchData(level: EquipmentConfigurationLevelDetail): EquipmentConfigurationLevelDetail {
    return this.emtService.isBranch(level) ? level : {
      environment: level.environment,
      tenantId: level.tenantId,
      branchNumber: level.branchNumber
    } as EquipmentConfigurationLevelDetail;
  }

  private createNode(
    id: string,
    text: string,
    detail?: EquipmentConfigurationLevelDetail,
    parent?: LevelNode): LevelNode {
    return {
      id,
      text,
      level: detail,
      children: [],
      parent: parent
    };
  }

  private getOrCreateNode(
    nodes: LevelNode[],
    id: string,
    name: string,
    levelData?: EquipmentConfigurationLevelDetail,
    parent?: LevelNode): LevelNode {
    let node = nodes.find(n => n.id === id);
    if (!node) {
      node = this.createNode(id, name, levelData, parent);
      nodes.push(node);
    }
    return node;
  }
  //Tree related methods end


}
